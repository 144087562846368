import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { AttachmentService } from "../../../../../services/api/attachment/attachment.service";
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { InstanceParamsService } from "../../../../../services/instance-params/instance-params.service";
import { RequestApiService } from "../../../../../services/api/request/request-api.service";
import { DialogService } from "../../../../../services/dialog-service/dialog.service";
import { ModalConfirmationComponent } from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import { RequestCreatEditDialogComponentComponent } from '../../request-creat-edit-dialog.component/request-creat-edit-dialog.component.component';
@Component({
  selector: 'app-request-thread-item',
  templateUrl: './request-thread-item.component.html',
  styleUrls: ['./request-thread-item.component.css']
})
export class RequestThreadItemComponent implements OnInit {

  @Input() threadItem;
  @Input() threadItemAttachments = [];
  @Input() isRequestClosed: boolean;
  @Output() refreshData = new EventEmitter<any>();
  @Input() isInDialog: boolean = false;

  instanceParams: any;
  originalBodyText: string;
  isSaving: boolean = false;
  isTextChanged: boolean = false;
  isSavingTaskComment: boolean = false;
  commentArray;

  constructor(private attachmentService: AttachmentService, private authService: AuthenticationService, private sanitizer: DomSanitizer,
    private instanceParamsService: InstanceParamsService, private requestApi: RequestApiService,  private dialogService: DialogService, 
    private viewReference: ViewContainerRef) 
    { }

  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getClassName() {
    switch (this.threadItem.ThreadType) {
      case 1:
        return 'inboundEmail';
      case 2:
        return 'outboundEmail'
      case 3:
        return 'comment'
      case 4:
        return 'resolution'
      case 5:
        return 'taskComment';
    }
  }

  getFromText() {

    if (this.threadItem.SystemTriggered) {
      return 'Service Desk Automation'
    }

    if (this.threadItem.AgentName) {
      return this.threadItem.AgentName;
    }
    if(this.getClassName()=='taskComment' && !this.threadItem.AgentName ){
      
      return this.instanceParams?.userName;
    }

    return `${this.threadItem.FromName}  (${this.threadItem.FromEmail})`

  }

  downloadAttachment(attachmentId) {
    this.attachmentService.downloadAttachment(attachmentId);
  }

  generateImages() {

    const authToken = this.authService.returnAuthToken();
    let str = this.threadItem.BodyText;

    this.threadItemAttachments.forEach(attachment => {
      const name = attachment.AttachmentName;
      const id = attachment.AttachmentId;

      var regex = new RegExp("src=\".*cid:" + name + "@.*?\"");

      console.log(regex);

      str = str.replace(regex, `src="/download/${id}?auth=${authToken}"`);
    })

    this.threadItem.BodyText = str;
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "";

    if (m == 0 && h == 0 && s != 0) {
      return sDisplay;
    } else {
      return hDisplay + mDisplay;
    }
  }

  ngOnInit(): void {
    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data;
    });
    this.generateImages();
  }

  // Toggle edit mode
  editMessage() {
    this.originalBodyText = this.threadItem.BodyText;
    this.isTextChanged = false;
    this.threadItem.isEditing = true;
  }

  onTextChange() {
    this.isTextChanged = this.threadItem.BodyText !== this.originalBodyText;
  }

  // Save edited message
  saveEdit() {
    this.isSaving = true;
    this.requestApi.threadUpdateComment(this.threadItem.RequestId, this.threadItem.BodyText, this.threadItem.Id).then(response => {
      this.isSaving = false;
      this.threadItem.isEditing = false;
      this.isTextChanged = false;
    })
  }

  // Delete comment 
  deleteComment() {
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => { 
        this.requestApi.threadDeleteComment(this.threadItem.RequestId, this.threadItem.Id).then(response => 
          this.refreshParent())
      }, cancelled => null)
  }

  // Cancel editing
  cancelEdit() {
    this.threadItem.BodyText = this.originalBodyText;
    this.isTextChanged = false;
    this.threadItem.isEditing = false;
  }

  refreshParent() {
    this.refreshData.emit();
  }

  // Give the capability of editing the task comment
  editTaskComment() {
    this.originalBodyText = this.threadItem.BodyText;
    this.isTextChanged = false;
    this.threadItem.isEditing = true;
  }

  //Save the edited comments
  saveEditTaskComment(){
    this.isSavingTaskComment = true;
    this.requestApi.threadUpdateTaskComment(this.threadItem.TaskId, this.threadItem.BodyText, this.threadItem.Id).then(response => {
      this.isSavingTaskComment = false;
      this.threadItem.isEditing = false;
      this.isTextChanged = false;
    })
  }
  
  // Cancel task comment editing
  cancelEditTaskComment() {
    this.threadItem.BodyText = this.originalBodyText;
    this.isTextChanged = false;
    this.threadItem.isEditing = false;
  }

  // Delete the task comment
  deleteTaskComment(){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => { 
        this.requestApi.threadDeleteTaskComment(this.threadItem.TaskId, this.threadItem.Id).then(response => 
          this.refreshParent())
      }, cancelled => null)
  }

  editTask(taskId,requestId,typeId){
    this.dialogService.createDialog(RequestCreatEditDialogComponentComponent, {taskId:taskId, requestId: requestId, typeId:typeId }, this.viewReference)
        .then(dialogSaved => {
          this.refreshParent()
        })
        .catch(dialogCancelled => {
          this.refreshParent()
        })
    
  }

  removeCommentItem(index: number): void {
    this.threadItem.notsaved = false;
    this.refreshData.emit();
  }

  // Method to edit the comment item
  saveEditTaskCommentTemp(index: number): void {
    this.isSavingTaskComment = true;
    this.threadItem.isEditing = false
    this.refreshData.emit();  // Notify the parent to refresh the data
  }
}