import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ApiAdminScheduledRequestsService} from "../../../../../services/api/admin/admin-scheduled-requests-api/admin-scheduled-requests-api.service";
import {Dialog} from "../../../../../shared/dialog/dialog";
import {OptionsApiService} from "../../../../../services/api/options/options-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {RequestApiService} from "../../../../../services/api/request/request-api.service";
import {Router} from "@angular/router";
import {RequesterSearchModalComponent} from "../../../../../shared/requester-search-modal/requester-search-modal.component";
import {CustomerServiceSearchModalComponent} from "../../../../../shared/customer-service-search-modal/customer-service-search-modal.component";
import { ApiService } from '../../../../../services/api/api.service';
import {ToastrService} from "ngx-toastr";


@Component({
  selector: 'app-admin-scheduled-requests-create-edit-dialog',
  templateUrl: './admin-scheduled-requests-create-edit-dialog.component.html',
  styleUrls: ['./admin-scheduled-requests-create-edit-dialog.component.css']
})

export class AdminScheduledRequestsComponentDialog implements Dialog {

  @Input() data; // {createNewWithRequestTypeId: 112 ,id: null}
  @Output() remove = new EventEmitter<any>();
  public time;
  taskLists;

  isLoading = true;
  isLoadingRequestTypes = true;
  isLoadingOptions = true;
  loadedId = null;
  minDate: Date;

  daysOfTheWeek = [
    {id: 1, name: 'Monday'},
    {id: 2, name: 'Tuesday'},
    {id: 3, name: 'Wednesday'},
    {id: 4, name: 'Thursday'},
    {id: 5, name: 'Friday'},
    {id: 6, name: 'Saturday'},
    {id: 7, name: 'Sunday'}
  ]

  loadedData;
  requestTypes;
  options;

  frequencyOptions = [
    {id: 1, name: 'Daily'},
    {id: 2, name: 'Weekly'},
    {id: 3, name: 'Monthly'}
  ];

  constructor(
    private apiAdminScheduledRequestsService: ApiAdminScheduledRequestsService,
    private apiOptions: OptionsApiService, private dialogService: DialogService, private viewReference: ViewContainerRef, private requestApi: RequestApiService,
    private router: Router, private api: ApiService, private toastr: ToastrService
  ) {
    this.apiOptions.AllRequestTypes().then(response => {
      this.requestTypes = response;
      this.isLoadingRequestTypes = false;
    })
  }


  ngOnInit(): void {
    this.minDate = new Date();                                                                                                               
    // If Id is sent... then its an edit.. else its create new
    if (this.data.id) {
      this.loadedId = this.data.id;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        RequestTypeId: this.data.createNewWithRequestTypeId,
        StartDateTime: '',
        Frequency: 3,
        Request_Subject: null,
        Request_Body: null,
        Request_RequesterId: null,
        Request_AgentId: null,
        Request_GroupId: null,
        Request_PriorityId: null,
        Request_StatusId: null,
        Request_CustomerId: null,
        Request_CustomerLocationId: null,
        Request_ServiceId: null,
        Request_UrgencyId: null,
        Request_ImpactId: null,
        Request_TaskListId:null,
        WeekdaysToRun: null,
        MonthFrequency:null,
        CustomFields: []
      };

      // Only load options if requestTypeId was provided
      if (this.data.createNewWithRequestTypeId) {
        this.refreshOptions();
      }

      this.isLoading = false;

    }
  }
  selectWeekDays(){
    if (this.loadedData.Frequency === 1){
      this.loadedData.WeekdaysToRun = this.daysOfTheWeek;
    }
  }

  refreshOptions(refreshSilently = false) {
    if (!refreshSilently) {
      this.isLoadingOptions = true;
    }

    this.apiOptions.DynamicOptions(this.loadedData.RequestTypeId, this.loadedData.Request_GroupId, this.loadedData.Request_CustomerId)
      .then(r => {
        this.options = r
        this.fetchTaskLists()
        this.isLoadingOptions = false;
      });

  }

  extractTimeFromDateTime(dateTimeString: string): Date {
    // Parse the dateTimeString to extract time components
    const timePart = dateTimeString.split(' ')[1]; // Get the "HH:mm:ss" part
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    // Create a new Date object and set the time components
    const time = new Date();
    time.setHours(hours);
    time.setMinutes(minutes);
    time.setSeconds(seconds);
    time.setMilliseconds(0); // Reset milliseconds

    return time;
  }


  getData() {
    this.isLoading = true;
    this.apiAdminScheduledRequestsService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.loadedData.StartDateTime = new Date(this.loadedData.StartDateTime);
      this.isLoading = false;
      this.refreshOptions();

    })
  }

  fetchTaskLists() {
    this.isLoading = true;
    this.api.get("/api/taskLists").then(response => {
      this.taskLists = response;
      this.isLoading = false;
    }).catch(error => {
      console.error('Failed to fetch task lists:', error);
      this.isLoading = false;
    });
  }


  getValueName(id, optionsArray: [any]) {
    const search = optionsArray.find(option => option.id == id)
    if (search) {
      return search.name;
    } else {
      return '...';
    }
  }

  setCustomFieldValue(data) {
    const customFieldId = data.customFieldId;

    const alreadyExists = this.loadedData.CustomFields.find(field => field.CustomFieldId == customFieldId);

    if (alreadyExists) {
      alreadyExists.Value = data.selected;
    } else {
      this.loadedData.CustomFields.push({
        CustomFieldId: customFieldId,
        Value: data.selected
      })
    }

    // if(this.ticketData.CustomFields[customFieldId]){
    //   this.ticketData.CustomFields[customFieldId]['Value'] = data.selected;
    // } else {
    //   this.ticketData.CustomFields[customFieldId] = [];
    //   this.ticketData.CustomFields[customFieldId]['Value'] = data.selected;
    // }

  }

  openUserSearchModal() {
    this.dialogService.createDialog(RequesterSearchModalComponent, null, this.viewReference)
      .then((dialogSaved: any) => {
        const userId = dialogSaved?.userId;
        const customerId = dialogSaved?.customerId;

        if (userId) {
          this.loadedData.Request_RequesterId = userId;
        }

        if (customerId && !this.loadedData.Request_CustomerId) {
          this.loadedData.Request_CustomerId = customerId;
        }

      }, dialogCancelled => {
        console.log("dialogCancelled:" + dialogCancelled)
      })

  }

  openCustomerServiceSearchModal() {

    const inputData = {
      customerId: this.loadedData.Request_CustomerId,
      customerLocationId: this.loadedData.Request_CustomerLocationId,
      serviceId: this.loadedData.Request_ServiceId
    };

    this.dialogService.createDialog(CustomerServiceSearchModalComponent, inputData, this.viewReference)
      .then((dialogSaved: any) => {

        if (dialogSaved.customerId) {
          this.loadedData.Request_CustomerId = dialogSaved.customerId;
        }

        if (dialogSaved.customerLocationId) {
          this.loadedData.Request_CustomerLocationId = dialogSaved.customerLocationId;
        }

        if (dialogSaved.serviceId) {
          this.loadedData.Request_ServiceId = dialogSaved.serviceId;
        }

      }, dialogCancelled => {
        console.log("dialogCancelled:" + dialogCancelled)
      })
  }

  close() {
    this.remove.emit(null);
  }

  save() {
    
    if(this.loadedData.StartDateTime===''){
      this.toastr.warning('Start Date Time is empty', 'Error');
      return;
    }

    // Use a temporary formatted value
    const formattedStartDate = this.formatDate(this.loadedData.StartDateTime); 

    // Create a copy of loadedData and assign the formatted date to it for the API call
    const requestData = { ...this.loadedData, StartDateTime: formattedStartDate };

    if (this.loadedId) {
      this.apiAdminScheduledRequestsService.update(this.loadedId, requestData).then(response => {
        this.remove.emit("Saved");
      }).catch(error => {   
        console.log("Update failed")
    });
    } else {
      this.apiAdminScheduledRequestsService.create(requestData).then(response => {
        this.remove.emit("Saved");
      }).catch(error => {   
        console.log("Create failed")
    });
    }
  }

  formatDate(input:string){
    let date = new Date(input);

    // Manually construct the local date string
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);

    let localDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return localDateString;
    }
  }

