import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../dialog/dialog";
import {OptionsApiService} from "../../services/api/options/options-api.service";
import {DialogService} from "../../services/dialog-service/dialog.service";
import {RequesterSearchModalComponent} from "../requester-search-modal/requester-search-modal.component";
import {CustomerServiceSearchModalComponent} from "../customer-service-search-modal/customer-service-search-modal.component";
import {RequestApiService} from "../../services/api/request/request-api.service";
import {Router} from "@angular/router";
import {AdminUsersComponentDialog} from "../../agent/pages/admin/user-groups/admin-users/admin-users-create-edit-dialog/admin-users-create-edit-dialog.component";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-ticket-blade',
  templateUrl: './new-ticket-blade.component.html',
  styleUrls: ['./new-ticket-blade.component.css']
})
export class NewTicketBladeComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;

  ticketData = {
    TypeId: null,
    RequestSubject: null,
    RequestBody: null,
    RequesterId: null,
    AgentId: null,
    GroupId: null,
    PriorityId: null,
    StatusId: null,
    CustomerId: null,
    CustomerLocationId: null,
    ServiceId: null,
    UrgencyId: null,
    ImpactId: null,
    CustomFields: []
  };

  ticketOptions;
  loading = false;
  previousCustomFields = [];

  constructor(private apiOptions: OptionsApiService, private dialogService: DialogService, private viewReference: ViewContainerRef, private requestApi: RequestApiService,
              private router: Router,private toastr: ToastrService,) {}

  ngOnInit(): void {
    this.ticketData.TypeId = this.data;

    this.refreshOptions();
  }

  refreshOptions(refreshSilently = false){
    if(!refreshSilently) {
      this.isLoading = true;
    }

    // Store the current custom field values before refreshing
    if (this.ticketOptions && this.ticketOptions.CustomFields) {
      this.previousCustomFields = [...this.ticketOptions.CustomFields];
    }

    this.apiOptions.DynamicOptionsSecurityGroup(this.ticketData.TypeId, this.ticketData.GroupId, this.ticketData.CustomerId)
      .then(r => {
        this.ticketOptions = r;
        
        // Restore custom field values after refresh
        if (this.previousCustomFields.length > 0) {
          this.restoreCustomFieldValues();
        }
        
        this.isLoading = false;
      });
  }

  restoreCustomFieldValues() {
    if (this.ticketOptions && this.ticketOptions.CustomFields) {
      this.ticketOptions.CustomFields.forEach(newField => {
        // Find the corresponding previous field with the same ID
        const prevField = this.previousCustomFields.find(f => f.CustomFieldId === newField.CustomFieldId);
        if (prevField) {
          newField.Value = prevField.Value;
          const dataField = this.ticketData.CustomFields.find(f => f.CustomFieldId === newField.CustomFieldId);
          if (dataField) {
          } else if (prevField.Value) {
            this.ticketData.CustomFields.push({
              CustomFieldId: newField.CustomFieldId,
              Value: prevField.Value,
              FieldType: newField.FieldType,
              isCompleted: prevField.isCompleted
            });
          }
        }
      });
    }
  }

  getSelectedLevelValues(customField) {
    const fieldData = this.ticketData.CustomFields.find(f => f.CustomFieldId === customField.CustomFieldId);
    
    if (fieldData && fieldData.Value) {
      const options = customField.Options || [];
      const level1Option = options.find(o => o.id === fieldData.Value && o.Level === 1);
      const level2Option = options.find(o => o.id === fieldData.Value && o.Level === 2);
      const level3Option = options.find(o => o.id === fieldData.Value && o.Level === 3);
      
      if (level3Option) {
        const level2Option = options.find(o => o.id === level3Option.ParentCustomFieldId);
        const level1Option = level2Option ? options.find(o => o.id === level2Option.ParentCustomFieldId) : null;
        
        return {
          Level1SelectedId: level1Option ? level1Option.id : null,
          Level2SelectedId: level2Option ? level2Option.id : null,
          Level3SelectedId: level3Option.id
        };
      } else if (level2Option) {
        const level1Option = options.find(o => o.id === level2Option.ParentCustomFieldId);
        
        return {
          Level1SelectedId: level1Option ? level1Option.id : null,
          Level2SelectedId: level2Option.id,
          Level3SelectedId: null
        };
      } else if (level1Option) {
        return {
          Level1SelectedId: level1Option.id,
          Level2SelectedId: null,
          Level3SelectedId: null
        };
      }
    }
    
    // Default empty values
    return {
      Level1SelectedId: null,
      Level2SelectedId: null,
      Level3SelectedId: null
    };
  }

  getValueName(id, optionsArray:[any]){
    const search = optionsArray.find(option => option.id == id)
    if(search){
      return search.name;
    } else {
      return '...';
    }
  }

  validateCustomFields() {
    let isValid = true;

    for (let customField of this.ticketOptions.CustomFields) {
      const customFieldData = this.ticketData.CustomFields.find(field => field.CustomFieldId === customField.CustomFieldId);
      if (customField.Required) {
        if (!customFieldData || !customFieldData.Value || (customField.FieldType === 2 && !customFieldData.isCompleted)) {
          this.toastr.warning(`Validation Error: ${customField.Name} is required.`);
          isValid = false;
        }
      }
    }
    return isValid;
  }


    setCustomFieldValue(customField, data) {

    const customFieldId = customField.CustomFieldId;

    if (customField.FieldType === 2) { // If FieldType is 2 (multiselect dropdown)
      const alreadyExists = this.ticketData.CustomFields.find(field => field.CustomFieldId === customFieldId);
 
      const isCompleted = customField.Required ? data.isCompleted : true;
  
      if (alreadyExists) {
        alreadyExists.Value = data.selected;
        alreadyExists.isCompleted = isCompleted;
      } else {
        this.ticketData.CustomFields.push({
          CustomFieldId: customFieldId,
          Value: data.selected,
          FieldType: customField.FieldType,
          isCompleted: isCompleted
        });
      }
    } else if (customField.FieldType === 1 || customField.FieldType === 3) { // If FieldType is 1 (textbox)
      const alreadyExists = this.ticketData.CustomFields.find(field => field.CustomFieldId === customFieldId);

        if (alreadyExists) {
          if(customField.FieldType === 3)
            {
              const convertedValue = this.convertdate(data)
              alreadyExists.Value = convertedValue;
            }
            else
            {
              alreadyExists.Value = data;
            }
        } else {
          if(customField.FieldType === 3)
          {
            let convertedValue = this.convertdate(data)
            this.ticketData.CustomFields.push({
              CustomFieldId: customFieldId,
              Value: convertedValue,
              FieldType: customField.FieldType
          });
          }
          else
          {
            this.ticketData.CustomFields.push({
              CustomFieldId: customFieldId,
              Value: data,
              FieldType: customField.FieldType
          });
          }
        }
    }
  }

convertdate(valueDate) {
  let date = new Date(valueDate);
  // Adjust the date to the desired output format "YYYY-MM-DD"
  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
  let day = String(date.getDate()).padStart(2, '0');
  let formattedDate = `${year}-${month}-${day}`;
  console.log("formattedDate", formattedDate);
  return formattedDate;
}

  openCreateUserModal(){
    // Replace null with Component
    this.dialogService.createDialog(AdminUsersComponentDialog, null, this.viewReference)
      .then(userIdReturned => {
        this.refreshOptions(true);

        console.log("UserIdReturned");
        console.log(userIdReturned);

        this.ticketData.RequesterId = userIdReturned;

      }, dialogCancelled => {

      })
  }

  openUserSearchModal(){
    this.dialogService.createDialog(RequesterSearchModalComponent, null, this.viewReference)
      .then((dialogSaved: any) => {
        const userId = dialogSaved?.userId;
        const customerId = dialogSaved?.customerId;

        console.log("UserIdReturned");
        console.log(userId);
        console.log(customerId);

        if(userId) {
          this.ticketData.RequesterId = userId;
        }

        if(customerId && !this.ticketData.CustomerId){
          this.ticketData.CustomerId = customerId;
        }

      }, dialogCancelled => {
        console.log("dialogCancelled:"+dialogCancelled)
      })

  }
  openCustomerServiceSearchModal(){

    const inputData = {
      customerId: this.ticketData.CustomerId,
      customerLocationId: this.ticketData.CustomerLocationId,
      serviceId: this.ticketData.ServiceId
    };

    this.dialogService.createDialog(CustomerServiceSearchModalComponent, inputData, this.viewReference)
      .then((dialogSaved: any) => {

        if(dialogSaved.customerId){
          this.ticketData.CustomerId = dialogSaved.customerId;
        }

        if(dialogSaved.customerLocationId){
          this.ticketData.CustomerLocationId = dialogSaved.customerLocationId;
        }

        if(dialogSaved.serviceId){
          this.ticketData.ServiceId = dialogSaved.serviceId;
        }

      }, dialogCancelled => {
        console.log("dialogCancelled:"+dialogCancelled)
      })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if (this.loading) return;

    if (!this.validateCustomFields()) {
      this.loading = false;
      return;
    }

    this.loading = true;
    this.requestApi.create(this.ticketData).then(
      response => {
        this.loading = false;
        this.router.navigate(['/agent/request/', response.Id]);
        this.close();
      }, error => {
        this.loading = false;
        if (error.status === 400) {
          const backendErrors = error.error.details;
          backendErrors.forEach(err => {
            this.toastr.warning(err);
          });
        }
        console.error(error);
      }
    );

    //this.remove.emit("DataToReturn");
  }

}
