import { Component, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { SelectionModel } from "@angular/cdk/collections";
import { ReportingApiService } from "../../../services/api/reporting/reporting-api.service";
import { OptionsApiService } from "../../../services/api/options/options-api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Table, TableModule } from 'primeng/table';
import { saveAs } from 'file-saver';
import { DialogService } from 'src/app/services/dialog-service/dialog.service';
import { ReportingFilterComponentDialog } from './reporting-filter-create-edit-dialog/reporting-filter-create-edit-dialog.component';
import { ModalConfirmationComponent } from 'src/app/shared/modal-confirmation/modal-confirmation.component';
import { SortEvent } from 'primeng/api/sortevent';
import { SdeskHelper } from 'src/app/shared/helper/SdeskHelper';
import {utils, write} from 'xlsx';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportingComponent implements OnInit {

  status = {};
  originPath = 1;
  fromLoadFilter = false; 
  statusCopy = {};
  groupSearch: String = '';
  agentSearch: String = "";
  customerSearch:String = "";
  filteredGroups:any[] = [];
  filteredAgents:any[] = [];
  filteredCustomers:any[] = [];
  isRequestTypesDropdownVisible: boolean;
  isGroupsDropdownVisible: boolean;
  isAgentsDropdownVisible: boolean;
  isCustomersDropdownVisible: boolean;
  isFilterDropdownVisible: boolean;
  allReportFilters:any[] = [];
  surveyCount;

  surveys:any[]=[];

  public sFilters: any;
  public orderTableBy = '';
  public viewLoading = true;
  public sort_report = {orderby: 0, ascending: null};
  public sortFied = 'Id';
  @ViewChild('dt') dt: Table;

  constructor(private toastr: ToastrService, private router: Router, private reportingApi: ReportingApiService, private route: ActivatedRoute,
    private optionsApi: OptionsApiService, private dialogService: DialogService,private viewReference: ViewContainerRef) {

  }



  selectedTab = '';
  predefinedDates = [
    { id: 'Last24h', name: 'Last 24 Hours' },
    { id: 'LastWeek', name: 'Last Week' },
    { id: 'LastMonth', name: 'Last Month' },
    { id: 'Last3Months', name: 'Last 3 Months' },
    { id: 'Last6Months', name: 'Last 6 Months' },
    { id: 'Last12Months', name: 'Last 12 Months' },
    { id: 'Custom', name: 'Custom Dates' }
  ]
  selectedReportPeriod = 'LastMonth';
  dateFrom = moment().subtract(1, 'months').startOf('month').toDate();
  dateTo = moment().subtract(1, 'months').endOf('month').toDate();

  filterTypeIds = new SelectionModel(true, []);
  filterGroupIds = new SelectionModel(true, []);
  filterAgentIds = new SelectionModel(true, []);
  filterStatusIds = new SelectionModel(true, []);
  filterCustomerIds = new SelectionModel(true, []);

  filterDateChanged = false;
  filterTypeIdsChanged = false;
  filterGroupIdsChanged = false;
  filterAgentIdsChanged = false;
  filterStatusIdsChanged = false;
  filterCustomerIdsChanged = false;

  totalNumberOfTickets: number= 0;
  isLoading = false;
  isLoadingStats = true;
  isLoadingTicketList = true;
  isLoadingOptions = true;
  isLoadingTimeElapsedReport = true;
  isStatusChanged = false;
  orderTableByAsc = true;

  hidetabTop = true;

  reportingData;
  ticketList;

  downloadableTickets;
  download_ticket_data_with_tasks;//get the data for downloading the report
  download_ticket_data_without_tasks;
  agentPerfData;
  timeElapsedData;
  timeElapsedCustomerData;
  timeElapsedSubCategory = 'manual'; // manual/tickets/group-customer
  agentPerfDataMaxValue; // Largest number of tickets solved by any 1 agent. Used for scaling the lines.
  options;


  surveyFilterTicketsRating; // 1 = Good, 2 = Avg, 3 = Bad

  //Paginator variables
  first: number = 0;
  rows: number = 50;
  showingRowscount: number = 50;
  fromRowscount: number = 1;
  currentPage: number = 1;
  currentPerPage: number= 50;
  public Report_storage: {Tab_name: string; taskId: number; perPage: number; page: number }[];
  viewName: string;

  filterGroups(){

    if(!this.groupSearch)
      {
        this.filteredGroups = this.options.groupEnabled
      }
      else{
        this.filteredGroups = this.options.groupEnabled.filter(g=>
          g.name.toLowerCase().includes(this.groupSearch.toLowerCase()) ||
          (g.enabled === 0 && `${g.name} (Inactive)`.toLowerCase().includes(this.groupSearch.toLowerCase()))
        )
      }
  }
  filterAgents(){
    if(!this.agentSearch)
    {
      this.filteredAgents = this.options.allAgent
    }
    else{

      this.filteredAgents = this.options.allAgent.filter(a =>
        a.name.toLowerCase().includes(this.agentSearch.toLowerCase()));
    }
  }
  filterCustomers(){
    if(!this.customerSearch)
    {
      this.filteredCustomers = this.options.customer
    }
    else{

      this.filteredCustomers = this.options.customer.filter(a =>
        a.name.toLowerCase().includes(this.customerSearch.toLowerCase()));
    }
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "";

    if (m == 0 && h == 0 && s != 0) {
      return sDisplay;
    } else {
      return hDisplay + mDisplay;
    }

  }

  downloadCsv() {
    let data = this.ticketList;
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, "myFile.csv");
  }

  surveyTicketList() {
    if (this.surveyFilterTicketsRating) {
      return this.surveys.filter(t => t.SurveyRating == this.surveyFilterTicketsRating);
    } else {
      return this.surveys.filter(t => t.SurveyStatus == "Completed");
    }
  }

  surveyTotalResponses() {
    return this.surveys.filter(t => t.SurveyStatus == "Completed").length;
  }
  surveyGoodResponses() {
    return this.surveys.filter(t => t.SurveyRating == 1).length;
  }
  surveyAverageResponses() {
    return this.surveys.filter(t => t.SurveyRating == 2).length;
  }
  surveyBadResponses() {
    return this.surveys.filter(t => t.SurveyRating == 3).length;
  }

  getSurveyBackgroundClass(surveyResult) {
    // 1 = good
    // 2 = average
    // 3 = bad

    switch (surveyResult) {
      case 1:
        return "background-green"
      case 2:
        return "background-amber"
      case 3:
        return "background-red"
    }
  }

  switchDates() {
    switch (this.selectedReportPeriod) {
      case "Last24h":
        this.dateFrom = moment().subtract(24, 'hours').toDate();
        this.dateTo = moment().toDate();
        break;
      case "LastWeek":
        this.dateFrom = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
        this.dateTo = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
        break;
      case "LastMonth":
        this.dateFrom = moment().subtract(1, 'months').startOf('month').toDate();
        this.dateTo = moment().subtract(1, 'months').endOf('month').toDate();
        break;
      case "Last3Months":
        this.dateFrom = moment().subtract(3, 'months').startOf('month').toDate();
        this.dateTo = moment().subtract(1, 'months').endOf('month').toDate();
        break;
      case "Last6Months":
        this.dateFrom = moment().subtract(6, 'months').startOf('month').toDate();
        this.dateTo = moment().subtract(1, 'months').endOf('month').toDate();
        break;
      case "Last12Months":
        this.dateFrom = moment().subtract(12, 'months').startOf('month').toDate();
        this.dateTo = moment().subtract(1, 'months').endOf('month').toDate();
        break;
      case "LastYear":
        this.dateFrom = moment().subtract(12, 'months').startOf('month').toDate();
        this.dateTo = moment().subtract(1, 'months').endOf('month').toDate();
        break;
      default:
    }
  }

  finishedLoading() {
    if (!this.isLoadingTicketList || !this.isLoadingStats || !this.isLoadingOptions) {
      return false
    } else {
      return true;
    }
  }

  exportExcel(withTask: boolean) {
      const data = withTask ? this.download_ticket_data_with_tasks : this.download_ticket_data_without_tasks;
      const worksheet = utils.json_to_sheet(data);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "tickets", withTask ? 'with_task' : 'without_task');
      this.isLoading = false;
      this.toastr.success("Finished Download", "Success");

    // import("xlsx").then(xlsx => {
    //   const data = withTask ? this.downloadableTickets : this.ticketList;
    //   const worksheet = xlsx.utils.json_to_sheet(data);
    //   const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    //   const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    //   this.saveAsExcelFile(excelBuffer, "tickets", withTask ? 'with_task' : 'without_task');
    // });
  }

  saveAsExcelFile(buffer: any, fileName: string, suffix: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, `${fileName}_export_${suffix}_${new Date().getTime()}${EXCEL_EXTENSION}`);
  }

  getData(fromApplyFilter = false) {
    if (this.fromLoadFilter) {
      this.fromLoadFilter = false;
    } else {
      // the use of "statusCopy" is to contain the original status elements of the requet types, with values to be "false"
      // then  using the "status" object, change the values accordingly, then uptimately overriding the original "status" object.
      // This code then retains the "selectability" of the other "status" even when you reload the screen
      if (this.originPath == 2 || this.originPath == 3) {
        this.resetSelectedByDefault(this.statusCopy);
      }
      // this "originPath" is intended only for the first on load
      this.originPath = 0;
      this.updateSelectedByDefault(this.statusCopy,this.status);
      // override here
      this.status= JSON.parse(JSON.stringify(this.statusCopy));
   }

    this.Report_storage = SdeskHelper.getViewResults('report_pagination') ;
    this.viewLoading = true;

    const Tab = this.Report_storage.find(item => item.Tab_name === this.selectedTab);
    console.log(Tab)
    if(Tab){
      this.currentPage = Tab.page;
      this.currentPerPage = Tab.perPage;
      console.log(this.currentPage)
      console.log(this.currentPerPage)
    }else {
      this.currentPage = 1;
      this.currentPerPage = 50;
    }
    this.rows=this.currentPerPage;
    this.first= this.currentPage;

    console.log("rows",this.rows)
    if (fromApplyFilter) {
      //if its coming from clicking the "apply filter button", save the filter options on local storage as cache
      this.sFilters = {filterTypeIds: null, filterGroupIds: null,
        filterAgentIds: null, filterStatusIds: null, filterCustomerIds: null};
      this.sFilters.filterTypeIds = this.filterTypeIds;
      this.sFilters.filterGroupIds = this.filterGroupIds;
      this.sFilters.filterAgentIds = this.filterAgentIds;
      this.sFilters.filterCustomerIds = this.filterCustomerIds;

      // <start> i got this piece of code on how to save request types and its status from "saveReportingFilter"
      const defaultStatusIds = new Set<number>();
      Object.values(this.status as { [key: number]: any[] }).forEach(statusList => {
        statusList.forEach(status => {
          // if (status.selectedByDefault) {
            defaultStatusIds.add(status.id);
          // }
        });
      });

      const filteredStatus: { [key: number]: any[] } = {};

      Object.entries(this.status as { [key: number]: any[] }).forEach(([key, statusList]) => {
        filteredStatus[key] = statusList.filter((status: { selectedByDefault: boolean }) => status.selectedByDefault);
      });

      // Add unique status IDs to filterStatusIds
      defaultStatusIds.forEach(id => {
        this.filterStatusIds.select(id);
      });
      // <end> i got this piece of code on how to save request types and its status from "saveReportingFilter"

      this.sFilters.filterStatusIds = this.filterStatusIds;
      this.sFilters.status = filteredStatus;

      this.saveFiltersOnStorage();
    }

    // Clear filterStatusIds
    this.filterStatusIds.clear();
    // Collect status IDs with selectedByDefault set to true
    const defaultStatusIds = new Set<number>();
    Object.values(this.status as { [key: number]: any[] }).forEach(statusList => {
      statusList.forEach(status => {
        // if (status.selectedByDefault) {
          defaultStatusIds.add(status.id);
        // }
      });
    });

    const filteredStatus: { [key: number]: any[] } = {};

    Object.entries(this.status as { [key: number]: any[] }).forEach(([key, statusList]) => {
      filteredStatus[key] = statusList.filter((status: { selectedByDefault: boolean }) => status.selectedByDefault);
    });

    // Add unique status IDs to filterStatusIds
    defaultStatusIds.forEach(id => {
      this.filterStatusIds.select(id);
    });


    this.isLoadingStats = true;
    this.isLoadingTicketList = true;
    this.isLoadingTimeElapsedReport = true;

    this.filterDateChanged = false;
    this.filterTypeIdsChanged = false;
    this.filterGroupIdsChanged = false;
    this.filterAgentIdsChanged = false;
    this.filterStatusIdsChanged = false;
    this.filterCustomerIdsChanged = false;

    this.reportingApi.getReportingData(
      this.dateFrom.toISOString(),
      this.dateTo.toISOString(),
      this.filterTypeIds.selected,
      this.filterGroupIds.selected,
      this.filterAgentIds.selected,
      filteredStatus,
      this.filterCustomerIds.selected).then(response => {
        this.reportingData = response;
        this.isLoadingStats = false;
      })

    this.reportingApi.getTimeElapsed(this.dateFrom.toISOString(),
      this.dateTo.toISOString()).then(response => {
        this.timeElapsedData = response;
        this.isLoadingTimeElapsedReport = false;
      })

    this.reportingApi.getTimeElapsedCustomer(this.dateFrom.toISOString(),
      this.dateTo.toISOString()).then(response => {
        this.timeElapsedCustomerData = response;
        this.isLoadingTimeElapsedReport = false;
      })
// Ticket without task
    this.reportingApi.getTicketList(
      this.dateFrom.toISOString(),
      this.dateTo.toISOString(),
      this.filterTypeIds.selected,
      this.filterGroupIds.selected,
      this.filterAgentIds.selected,
      filteredStatus,
      this.filterCustomerIds.selected,false,this.currentPage,   //added current page and current perpage
      this.currentPerPage,
      SdeskHelper.getColId(this.orderTableBy),
      this.orderTableByAsc).then(response => {
        this.ticketList = response.Results;

        // sort the ticket based on retrieved info
        // this.ticketList.sort((data1, data2, ascending: boolean = false) => {

        //   // get sort from storage
        //   this.sort_report = JSON.parse(localStorage.getItem('sort_report'));

        //   let field = SdeskHelper.getColName(this.sort_report.orderby, SdeskHelper.REPORTING);
        //   let order = this.sort_report.ascending;

        //   this.sortFied = field;

        //   let value1 = data1[field];
        //   let value2 = data2[field];
        //   let result = null;

        //   if (value1 == null && value2 != null) result = -1;
        //   else if (value1 != null && value2 == null) result = 1;
        //   else if (value1 == null && value2 == null) result = 0;
        //   else if (typeof value1 === 'string' && typeof value2 === 'string')
        //     result = value1.localeCompare(value2);
        //   else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        //   return order * result;
        //   });

        this.totalNumberOfTickets = response.TotalCount;
        this.isLoadingTicketList = false;
        this.getAgentPerformanceInfo()
      })

      //Ticket with task
        this.reportingApi.getTicketList(
          this.dateFrom.toISOString(),
          this.dateTo.toISOString(),
          this.filterTypeIds.selected,
          this.filterGroupIds.selected,
          this.filterAgentIds.selected,
          filteredStatus,
          this.filterCustomerIds.selected,   //added current page and current perpage
        true, this.currentPage,
        this.currentPerPage,
        SdeskHelper.getColId(this.orderTableBy), this.orderTableByAsc).then(response => {
            this.downloadableTickets = response;
            this.isLoadingTicketList = false;
            this.getAgentPerformanceInfo()

          })

          this.setFirstPageIndex();
          this.getAllSurvey();


  }

  getAllSurvey(){
    this.reportingApi.getAllSurvey().then(response => {
      this.surveys = response;
      this.surveyCount = this.surveys.length;
      });
  }

  getReportFilterDetails(id){
    this.fromLoadFilter = true;
    console.log(id);
    this.reportingApi.getReportingFilterDetails(id).then((res)=>{
      console.log(res);
      this.filterAgentIds.clear()
      this.filterCustomerIds.clear()
      this.filterGroupIds.clear()
      this.filterTypeIds.clear()
      this.filterAgentIds.select(...res.Filter.agent);
      this.filterCustomerIds.select(...res.Filter.customer);
      this.filterGroupIds.select(...res.Filter.group) ;
      this.filterTypeIds.select(...res.Filter.type);
      this.status = res.Filter.status;
      this.selectedReportPeriod = res.CustomDateID;
      this.switchDates()
      if (this.selectedReportPeriod === "Custom" ) {
        this.dateFrom = new Date(res.DateFrom)
      }

      if (this.selectedReportPeriod === "Custom" ) {
          this.dateTo = new Date(res.DateTo);
      }
      this.getData();
      // save the selected filter to local storge
      SdeskHelper.initReportFilterStorage(res);
      // remove the date filter from storage. Only one or the other type can be stored on local storage
      SdeskHelper.removeDatesFilter();
    }).catch((e)=>{
      console.log(e);
    })
  }

  saveReportingFilter(){
    const defaultStatusIds = new Set<number>();
    Object.values(this.status as { [key: number]: any[] }).forEach(statusList => {
      statusList.forEach(status => {
        if (status.selectedByDefault) {
          defaultStatusIds.add(status.id);
        }
      });
    });

    const filteredStatus: { [key: number]: any[] } = {};

    Object.entries(this.status as { [key: number]: any[] }).forEach(([key, statusList]) => {
      filteredStatus[key] = statusList.filter((status: { selectedByDefault: boolean }) => status.selectedByDefault);
    });

    // Add unique status IDs to filterStatusIds
    defaultStatusIds.forEach(id => {
      this.filterStatusIds.select(id);
    });

   const  data = {
    filter: {
      type: this.filterTypeIds.selected,
      group: this.filterGroupIds.selected,
      agent: this.filterAgentIds.selected,
      status: filteredStatus,
      customer: this.filterCustomerIds.selected
    },
    customDateID:this.selectedReportPeriod,
    dateFrom:null,
    dateTo:null,
    name: null

  }
  if(this.selectedReportPeriod==="Custom"){
    data.dateFrom = this.dateFrom
    data.dateTo = this.dateTo
  }
    this.dialogService.createDialog(ReportingFilterComponentDialog, data, this.viewReference).then((dialogSaved)=>{
      console.log(dialogSaved)
      this.getReportFilters()
      this.getReportFilterDetails(dialogSaved);
    })

  }

  deleteReportingFilter(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.reportingApi.deleteReportingFilter(id).then((response)=>{
          this.getReportFilters()
          this.getData()
        } )
      }, cancelled => null)
  }

  getOptions() {
    this.isLoadingOptions = true;
    this.optionsApi.AllOptions().then(response => {
      this.getOptionsCommon(response);
      this.getData();
    })
  }

  public updateSelectedByDefault(targetData, sourceData) {
    // Iterate over keys of the source data
    for (const key in sourceData) {
      if (Array.isArray(sourceData[key])) {
        sourceData[key].forEach(sourceItem => {
          // Find matching item in the target data
          const targetItems = targetData[key];
          if (targetItems) {
            const matchingItem = targetItems.find(
              targetItem =>
                targetItem.id === sourceItem.id &&
                targetItem.name === sourceItem.name
            );
            // Update the selectedByDefault value if a match is found
            if (matchingItem) {
              matchingItem.selectedByDefault = sourceItem.selectedByDefault;
            }
          }
        });
      }
    }
  }

  public resetSelectedByDefault(data) {
    // Loop through the keys of the main object
    for (const key in data) {
      if (Array.isArray(data[key])) {
        // Loop through the array and update the property
        data[key].forEach(item => {
          if (item.hasOwnProperty('selectedByDefault')) {
            item.selectedByDefault = false;
          }
        });
      }
    }
  }  
  getOptionsCommon(response: any) {
    this.options = response;
    this.filteredGroups = response.groupEnabled;
    this.filteredAgents = response.allAgent;
    this.filteredCustomers = response.customer
    // this.status = response.status;
    this.options.type.forEach(type => {
      // Initialize an array for the current type's statuses
      const typeStatuses = [];

      // Iterate over the statuses to find those associated with the current type
      this.options.status.forEach(status => {
        if ((status["TypeId"] === type["id"] || status["TypeId"] === null) &&
          status["name"] !== "Deleted" && status["name"] !== "Cancelled") {
          // Push the status object to the typeStatuses array
          typeStatuses.push({ id: status["id"], name: status["name"] });
        }
      });

      // Assign the typeStatuses array to the corresponding TypeId in this.status
      this.status[type["id"]] = typeStatuses;
      this.statusCopy[type["id"]] = typeStatuses;
    });
    console.log(this.status);

    // Add all request types to filter
    this.options.type.map(type => {
      this.filterTypeIds.select(type.id);
    })

    // Add all groups to filter
    this.options.groupEnabled.push({ id: -1, name: "Unassigned Groups" })
    this.options.groupEnabled.map(group => {
      this.filterGroupIds.select(group.id);
    })

    // Add all agents to filter
    this.options.allAgent.push({ id: -1, name: "Unassigned Agents" })
    this.options.allAgent.map(agent => {
      this.filterAgentIds.select(agent.id);
    })

    this.options.customer.push({ id: -1, name: "Unassigned Customer" })
    this.options.customer.map(customer => {
      this.filterCustomerIds.select(customer.id);
    })

    // this.options.status.map(status => {
    //   this.filterStatusIds.select(status.id);
    // })

    this.isLoadingOptions = false;
  }

  roundMinutesToHours(minutes) {

    if (minutes > 60) {
      let returnHours = Math.floor((minutes / 60));
      let returnMinutes = Math.trunc(minutes - (returnHours * 60));

      let returnString = `${returnHours}h`

      if (returnMinutes > 0) {
        returnString += `  ${returnMinutes}m`
      }

      return returnString;

    } else {
      let returnMinutes = Math.trunc(minutes);
      return `${returnMinutes}m`
    }
  }

  toggleStatusSelection(status: any, typeId: number) {
    status.selectedByDefault = !status.selectedByDefault;
    this.filterStatusIdsChanged = true;

    const areAllStatusesUnselected = this.status[typeId].every(s => !s.selectedByDefault);

    if (areAllStatusesUnselected) {
      // If all statuses of the type are unselected, deselect the type
      this.filterTypeIds.deselect(typeId);
    } else if (!this.filterTypeIds.isSelected(typeId)) {
      // If a status of a type is selected and the type is not selected, select that type
      this.filterTypeIds.select(typeId);
      this.isStatusChanged = true;
    }
    // Toggle status selection logic
  }

  getAverageResponseTime() {
    const averageResponseTime = this.ticketList.reduce((accumulator, currentValue) => accumulator + currentValue.ResponseSlaElapsedMinutes, 0) / this.ticketList.length;
    return this.roundMinutesToHours(averageResponseTime);
  }

  getAverageResolutionTime() {
    const avgTime = this.ticketList.reduce((accumulator, currentValue) => accumulator + currentValue.ResolutionSlaElapsedMinutes, 0) / this.ticketList.length;
    return this.roundMinutesToHours(avgTime);
  }

  getAgentPerformanceInfo() {
    const returnInfo = [];

    const distinctAgentNames = [...new Set(this.ticketList.map(x => x.Agent))];

    distinctAgentNames.forEach(agent => {
      const agentsTickets = this.ticketList.filter(ticket => ticket.Agent == agent);
      const ticketsBreachedSla = agentsTickets.filter(ticket => (ticket.SlaResolutionOverdue == 1 || ticket.SlaResponseOverdue == 1) && ticket.ResponseDueDate && ticket.ResolutionDueDate)
      const ticketsWithinSla = agentsTickets.filter(ticket => (ticket.SlaResolutionOverdue == 0 && ticket.SlaResponseOverdue == 0) && ticket.ResponseDueDate && ticket.ResolutionDueDate)
      const averageResponseTime = this.roundMinutesToHours(agentsTickets.reduce((accumulator, currentValue) => accumulator + currentValue.ResponseSlaElapsedMinutes, 0) / agentsTickets.length);

      returnInfo.push({
        name: agent,
        agentsTickets: agentsTickets,
        ticketsBreachedSla: ticketsBreachedSla,
        ticketsWithinSla: ticketsWithinSla,
        averageResponseTime: averageResponseTime,
        // percentOfTotalTickets: agentsTickets.length / this.ticketList.length * 100,
        percentWithinSla: ticketsWithinSla.length / agentsTickets.length * 100,
        percentBreached: ticketsBreachedSla.length / agentsTickets.length * 100,
        tooltipWithinSla: `Tickets within SLA ${ticketsWithinSla.length}`,
        tooltipBreachedSla: `Tickets breached SLA ${ticketsBreachedSla.length}`
      })

    })
    this.agentPerfData = returnInfo;
    console.log("dsfsdf", this.agentPerfData)

    // Largest number of tickets solved by any 1 agent. Used for scaling the lines.

    this.agentPerfDataMaxValue = Math.max(...returnInfo.map(o => o.agentsTickets.length), 0);
    //this.agentPerfDataMaxValue = this.agentPerfData.reduce((prev, current) => (prev.agentsTickets.length > current.agentsTickets.length) ? prev : current)



    this.route.paramMap.subscribe(params => {
      this.selectedTab = params.get("tab");
    })


    // console.log(this.agentPerfDataMaxValue);

  }

  changeStatus(changed) {
    if (changed.removed.length != 0) {
      changed.removed.forEach(element => {
        this.status[element].forEach(status => {
          status.selectedByDefault = false;
        });
      });
    }

    if (changed.added.length != 0) {
      changed.added.forEach(element => {
        this.status[element].forEach(status => {
          status.selectedByDefault = true;
        });
      });
    }
  }

  private saveOnStorage(event: SortEvent) {
    let orderby = SdeskHelper.getColId(event.field);
    let ascending = event.order;
    SdeskHelper.execSortStoreRoutine('sort_report', orderby, ascending);

  }

  sortColumn(column) {
    if (this.orderTableBy == column) {
      this.orderTableByAsc = !this.orderTableByAsc;
      this.sortData();
    } else {
      this.orderTableBy = column;
      this.orderTableByAsc = true;
      this.sortData();
    }
    SdeskHelper.execSortStoreRoutine('sort_report', SdeskHelper.getColId(column), Number(this.orderTableByAsc));
  }

  sortData() {
    this.Report_storage = SdeskHelper.getViewResults('report_pagination') ;
    this.viewLoading = true;

    const Tab = this.Report_storage.find(item => item.Tab_name === this.selectedTab);
    console.log(Tab)
    if(Tab){
      this.currentPage = Tab.page;
      this.currentPerPage = Tab.perPage;
      console.log(this.currentPage)
      console.log(this.currentPerPage)
    }else {
      this.currentPage = 1;
      this.currentPerPage = 50;
    }
    this.rows=this.currentPerPage;
    this.first= this.currentPage;
    this.getData();
  }

  private initSortStorage() {
    this.sort_report = JSON.parse(localStorage.getItem('sort_report'));
    // if not existing on local storage, create it
    if (this.sort_report === null) {
      let sort_obj = {orderby: 0, ascending: null};
      localStorage.setItem('sort_report', JSON.stringify(sort_obj));
    } else {
      this.orderTableBy = SdeskHelper.getColName(this.sort_report.orderby, SdeskHelper.REPORTING);
   }
  }

  ngOnInit(): void {
    this.initSortStorage();

    // there a 2 tyoes of saved filter states on local storage: the "reporting_filter" and "reporting_filter_dates".
    // the  "reporting_filter" is when the user clicks on any of the saved filters, the "reporting_filter_dates" does the selection
    // of start and end dates for filtering or toggle ny of the other options. On load, it checks first for "reporting_filter" on local storage, if its not existing, it looks for
    // "reporting_filter_dates". If it cannot find both on local storage, it goes on its normal mode

    //check if came from saved reporting fileter
    let reporting_filter: any = SdeskHelper.getReportFilter();
    if (SdeskHelper.isNotNullOrEmpty(reporting_filter)) {
      this.originPath = 2;
      this.doDefultsForReportingFilter(reporting_filter);
    } else {
      // check if there is a custom filter date saved on local storage. If there is, load the date filter
      let filterDates:any = {selectedReportPeriod: null, dateFrom: null, dateTo: null, filters: null};
      filterDates = SdeskHelper.getDatesFilter();

      if (SdeskHelper.isNotNullOrEmpty(filterDates)) {

        if (SdeskHelper.isNotNullOrEmpty(filterDates.dateFrom) &&
          SdeskHelper.isNotNullOrEmpty(filterDates.dateTo)) {

            // assign the dateFrom and DateTo
            this.dateFrom = new Date(filterDates.dateFrom);
            this.dateTo = new Date(filterDates.dateTo);

            // change the "Date Selector" dropdown to whatever the value of "selectedReportPeriod"
            this.selectedReportPeriod = filterDates.selectedReportPeriod;

            this.originPath = 3;
            //do the filtering based on those parameters
            this.doDefultsForReportingFilterDates(filterDates);
        } else {
          this.originPath = 4;
          this.doDefults();
        }
      } else {
        this.originPath = 1;
        this.doDefults();
      }
    }
  }

  private doDefults() {
    this.doDefultsCommon();
    this.getOptions();
  }

  private doDefultsCommon() {
    // this.getOptions();
    this.filterTypeIds.changed.subscribe(changed => {
      this.filterTypeIdsChanged = true;
      this.changeStatus(changed)
    })
    this.filterGroupIds.changed.subscribe(changed => {
      this.filterGroupIdsChanged = true;
    })
    this.filterAgentIds.changed.subscribe(changed => {
      this.filterAgentIdsChanged = true;
    })
    this.filterStatusIds.changed.subscribe(changed => {
      this.filterStatusIdsChanged = true;
    })
    this.filterCustomerIds.changed.subscribe(changed => {
      this.filterCustomerIdsChanged = true;
    })

    this.route.paramMap.subscribe(params => {
      this.selectedTab = params.get("tab");
    })

    this.getReportFilters()
  }

  ngAfterViewInit(): void {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      // @ts-ignore
      return new bootstrap.Popover(popoverTriggerEl)
    })
  }

  clearSelection() {
    this.ticketList.clear();
  }

  selectedDropdown: number | null = null;

  toggleDropdown(typeId: number) {
    // this is code that makess the "status type" dropdown to hide/show
    this.selectedDropdown = this.selectedDropdown === typeId ? null : typeId;
  }

  getStatusesByTypeId(typeId: number) {
    return this.status[typeId] || []; // Return an empty array if typeId is not found
  }
  
  isDropdownVisible(typeId: number): boolean {
    return this.selectedDropdown === typeId;
  }

  getReportFilters(){
    this.reportingApi.getAllReportingFilters().then((res)=>{
      this.allReportFilters = res;
    }).catch((e)=>{
      console.log(e);
    })
  }


  toggleRequestTypesDropdown() {
    this.isRequestTypesDropdownVisible = !this.isRequestTypesDropdownVisible;
  }
  toggleGroupsDropdown() {
    this.isGroupsDropdownVisible = !this.isGroupsDropdownVisible;
  }
  toggleAgentsDropdown() {
    this.isAgentsDropdownVisible = !this.isAgentsDropdownVisible;
  }

  toggleCustomerDropDown(){
    this.isCustomersDropdownVisible = !this.isCustomersDropdownVisible;
  }

  toggleFilterDropDown(){
    this.isFilterDropdownVisible = !this.isFilterDropdownVisible
  }

  isAllRequestTypesOptionsSelected(): boolean {
    return this.filterTypeIds.selected.length === this.options.type.length;
  }

  isAllGroupsOptionsSelected(): boolean {
    return this.filterGroupIds.selected.length === this.options.groupEnabled.length;
  }

  isAllAgentsOptionsSelected(): boolean {
    return this.filterAgentIds.selected.length === this.options.allAgent.length;
  }

  isAllCustomerOptionsSelected(): boolean {
    return this.filterCustomerIds.selected.length === this.options.customer.length;
  }

  toggleSelectAll(selectAll: boolean, optionType: string): void {
    let selectionModel;
    let options;

    switch (optionType) {
      case 'requestTypes':
        selectionModel = this.filterTypeIds;
        options = this.options.type;
        break;
      case 'groups':
        selectionModel = this.filterGroupIds;
        options = this.options.groupEnabled;
        break;
      case 'agents':
        selectionModel = this.filterAgentIds;
        options = this.options.allAgent;
        break;
      case 'customer':
        selectionModel = this.filterCustomerIds;
        options = this.options.customer;
        break;
      default:
        return;
    }

    if (selectAll) {
      options.forEach(option => {
        selectionModel.select(option.id);
      });
    } else {
      options.forEach(option => {
        selectionModel.deselect(option.id);
      });
    }
  }

  private doDefultsForReportingFilter(reporting_filter) {
    this.doDefultsCommon();
    this.getOptionsForReportingFilter(reporting_filter);
  }

  public getOptionsForReportingFilter(reporting_filter) {
    this.isLoadingOptions = true;
    this.optionsApi.AllOptions().then(response => {
      this.getOptionsCommon(response);

      // this is the part i added to load the filter params before loading of data
      let res: any = reporting_filter.res;

      this.filterAgentIds.clear()
      this.filterCustomerIds.clear()
      this.filterGroupIds.clear()
      this.filterTypeIds.clear()

      this.filterAgentIds.select(...res.Filter.agent);
      this.filterCustomerIds.select(...res.Filter.customer);
      this.filterGroupIds.select(...res.Filter.group) ;
      this.filterTypeIds.select(...res.Filter.type);
      // this.filterStatusIds.select(...res.Filter.status);

      this.status = res.Filter.status;
      this.selectedReportPeriod = res.CustomDateID;
      this.switchDates()
      if (this.selectedReportPeriod === "Custom" ) {
        this.dateFrom = new Date(res.DateFrom)
      }

      if (this.selectedReportPeriod === "Custom" ) {
          this.dateTo = new Date(res.DateTo);
      }

      this.getData();

      // remove the date filter from storage
      SdeskHelper.removeDatesFilter();

      // this toggles the "Filter" dropdown to indicated that the user came from a clicked saved custom filter
      this.toggleFilterDropDown();
    })
  }

  private doDefultsForReportingFilterDates(reporting_filter_dates) {
    this.doDefultsCommon();
    this.getOptionsForReportingFilterDates(reporting_filter_dates);
  }

  public getOptionsForReportingFilterDates(reporting_filter_dates) {
    this.isLoadingOptions = true;
    this.optionsApi.AllOptions().then(response => {
      this.getOptionsCommon(response);

      // this is the part i added to load the filter params before loading of data
      let res: any = reporting_filter_dates;

      this.filterAgentIds.clear()
      this.filterCustomerIds.clear()
      this.filterGroupIds.clear()
      this.filterTypeIds.clear()

      this.filterAgentIds.select(...res.filters.filterAgentIds._selected);
      this.filterCustomerIds.select(...res.filters.filterCustomerIds._selected);
      this.filterGroupIds.select(...res.filters.filterGroupIds._selected);
      this.filterTypeIds.select(...res.filters.filterTypeIds._selected);
      if (SdeskHelper.isNotNullOrEmpty(res.filters.filterStatusIds._selected)) {
        this.filterStatusIds.select(...res.filters.filterStatusIds._selected);
      }

      this.status = res.filters.status;
      this.selectedReportPeriod = res.selectedReportPeriod;

      this.switchDates()
      this.getData();

      // // remove the date filter from storage
      SdeskHelper.removeReportFilter();
    })
  }

  deleteCacheFilter() {
    SdeskHelper.removeDatesFilter();
    SdeskHelper.removeReportFilter();
    this.isLoadingOptions = true;
    this.isLoadingStats = true;
    this.isLoadingTicketList = true;
    window.location.reload();
    // this.reloadCurrentRoute();
  }

  confirmeleteCacheFilter(){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.deleteCacheFilter();
      }, cancelled => null)
  }

  hasCacheFilter(): boolean {
    let filterObj: any = null;
    let filterObjDate: any = null;

    filterObjDate = SdeskHelper.getDatesFilter();
    filterObj = SdeskHelper.getReportingFilter();

      if ( SdeskHelper.isNotNullOrEmpty(filterObj) || SdeskHelper.isNotNullOrEmpty(filterObjDate)    ) {
        return true;
      } else {
        return false;
      }
  }

  applyFilterVisible(): boolean {
    return (this.filterAgentIdsChanged || this.filterDateChanged || this.filterTypeIdsChanged || this.filterGroupIdsChanged || this.filterStatusIdsChanged || this.filterCustomerIdsChanged);
  }

  saveFiltersOnStorage() {
    if (SdeskHelper.isNotNullOrEmpty(this.dateFrom) && SdeskHelper.isNotNullOrEmpty(this.dateTo) ) {
      // save it
      SdeskHelper.saveDatesFilter(this.selectedReportPeriod, this.dateFrom, this.dateTo, this.sFilters);
    }
  }

  reloadCurrentRoute() {
    this.router.navigate([this.router.url], { skipLocationChange: true });
  }

  public removeFalseSelectedEntries(data) {
    for (const key in data) {
      if (Array.isArray(data[key])) {
        // Filter the array to keep only items with selectedByDefault set to true
        data[key] = data[key].filter(item => item.selectedByDefault);
      }
    }
  }

  downloadReports(includeTasks: boolean) {
    let statusObj: any;
    if (this.sFilters && this.sFilters.status) {
      statusObj = this.sFilters.status;
    } else {
      statusObj = this.status
    }

    //make a copu, we have to do this to not alter the original structure of status. If these with false are removed, you cant select those options anymore on the request type status
    let statusObjCopy = JSON.parse(JSON.stringify(statusObj));

    // remove from downloadReports those status with "selectedByDefault = false"
    // the backend should only receive the actual values
    this.removeFalseSelectedEntries(statusObjCopy);

    this.isLoading = true;
    this.reportingApi.downloadReportingData(
      this.dateFrom.toISOString(),
      this.dateTo.toISOString(),
      this.filterTypeIds.selected,
      this.filterGroupIds.selected,
      this.filterAgentIds.selected,
      statusObjCopy,
      this.filterCustomerIds.selected,
      includeTasks // Pass the flag for tasks
    ).then(response => {
      if (includeTasks) {
        this.download_ticket_data_with_tasks = response;
      } else {
        this.download_ticket_data_without_tasks = response;
      }
      // Call exportExcel based on includeTasks flag
      this.exportExcel(includeTasks);
    });
  }

  //Function uses for pagination
  onPageChange(event) {
    this.currentPage = event.page + 1;
    this.rows = event.rows;
    this.first = event.first;
    this.currentPerPage = this.rows;

    this.showingRowscount = event.page * event.rows;
    this.fromRowscount = (event.page - 1) * event.rows + 1;

    SdeskHelper.storePaginationData('report_pagination', "Tab_name", this.selectedTab, this.viewName, this.currentPerPage, this.currentPage);
    this.getData(); // Fetch the updated data for the new page
  }

  setFirstPageIndex() {
    this.first = (this.currentPage - 1) * this.rows;
  }
}



