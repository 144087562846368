
<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading" class="container-fluid p-l-20 p-r-20">
  
  <!-- Trigger Delay Section -->
  <div class="row mb-4 align-items-center">
    <div class="col-auto">
      <label for="TriggerDelay" class="formHeader mb-0 me-3">Trigger Delay (Hrs) <span class="text-danger">*</span>
        <span>
          <i [matTooltip]="'Use this trigger delay to customize when the survey is received. Example, having 48 hrs delay means the survey will be sent 48 hrs after the ticket has been resolved.'"
          [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
          class="far fa-question-circle m-l-5"></i>
        </span>
      </label>
    </div>
    <div class="col-auto">
      <input [(ngModel)]="triggerDelay" 
             type="number" 
             class="form-control form-control-sm" 
             id="TriggerDelay" 
             placeholder="Enter Trigger Delay" 
             autocomplete="off" 
             min="0"
             style="width: 200px;">
    </div>
    <div class="col-auto ms-2">
      <button (click)="updateTriggerDelay()" 
              class="btn btn-primary btn-sm" 
              [disabled]="isSaving">Save</button>
    </div>
  </div>

  <div class="row mb-4 align-items-center">
    <div class="col-auto">
      <label for="SurveyFrequency" class="formHeader mb-0 me-3">Survey Frequency (Days) <span class="text-danger">*</span>
        <span>
          <i [matTooltip]="'Adjust the survey frequency in days. For example, setting 30 means the survey will be sent every 30 days'"
          [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
          class="far fa-question-circle m-l-5"></i>
        </span>
      </label>
    </div>
    <div class="col-auto">
      <input [(ngModel)]="surveyfrequency" 
             type="number" 
             class="form-control form-control-sm" 
             id="SurveyFrequency" 
             placeholder="Enter Survey Frequency" 
             autocomplete="off" 
             min="0"
             style="width: 200px;">
    </div>
    <div class="col-auto ms-2">
      <button (click)="updateSurveyFrequency()" 
              class="btn btn-primary btn-sm" 
              >Save</button>
    </div>
  </div>
  
  <!-- Custom Survey List Section -->
  <div class="row">
    <div class="col-12">
      <table class="table align-middle tableStyle">
        <thead>
          <tr>
            <th class="col-sm-12">Custom Survey
              <button (click)="create()" class="btn btn-primary float-end">Create</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of data">
            <td>
              <span class="divLink" (click)="edit(row.Id)">{{ row.FieldName }}</span>
              <i class="far fa-trash-alt float-end m-r-20 divLink" (click)="delete(row.Id)"></i>
              <i class="fas fa-cog float-end m-r-20 divLink" (click)="edit(row.Id)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>
