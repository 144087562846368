<div id="changeManagement" class="row">
  <div id="changeTabs" class="row">
    <div class="col">
      <ul class="nav">
        <li class="nav-item m-r-1 top-tab divLink" [class.active]="changeTabSelected == 1" (click)="changeTab(1)">
          <a>Open
            <i *ngIf="requestDetails.ChangeManagement.ChangeState >= 1" class="fas fa-check-circle green-icon"></i>
          </a>
        </li>

        <li class="nav-item m-r-1 top-tab divLink" [class.active]="changeTabSelected == 2" (click)="changeTab(2)">
          <a>Planning
            <i *ngIf="requestDetails.ChangeManagement.ChangeState > 2" class="fas fa-check-circle green-icon"></i>
            <i *ngIf="requestDetails.ChangeManagement.ChangeState == 2" class="fas fa-hourglass-start"></i>
          </a>
        </li>

        <li class="nav-item m-r-1 top-tab divLink" [class.active]="changeTabSelected == 3" (click)="changeTab(3)">
          <a>Schedule
            <i *ngIf="requestDetails.ChangeManagement.ChangeState > 3" class="fas fa-check-circle green-icon"></i>
            <i *ngIf="requestDetails.ChangeManagement.ChangeState == 3" class="fas fa-hourglass-start"></i>
          </a>
        </li>

        <li class="nav-item m-r-1 top-tab divLink" [class.active]="changeTabSelected == 4" (click)="changeTab(4)">
          <a>Approval
            <i *ngIf="requestDetails.ChangeManagement.ChangeState > 4" class="fas fa-check-circle green-icon"></i>
            <i *ngIf="requestDetails.ChangeManagement.ChangeState == 4" class="far fa-hourglass"></i>
          </a>
        </li>

        <li class="nav-item m-r-1 top-tab divLink" [class.active]="changeTabSelected == 5" (click)="changeTab(5)">
          <a>Implementation
            <i *ngIf="requestDetails.ChangeManagement.ChangeState > 5 && requestDetails.ChangeManagement.ChangeImplemented" class="fas fa-check-circle green-icon"></i>
            <i *ngIf="requestDetails.ChangeManagement.ChangeState > 5 && !requestDetails.ChangeManagement.ChangeImplemented" class="fa fa-times-circle" style="color: indianred"></i>
            <i *ngIf="requestDetails.ChangeManagement.ChangeState == 5" class="far fa-hourglass"></i>
          </a>
        </li>

        <li class="nav-item m-r-1 top-tab divLink" [class.active]="changeTabSelected == 6" (click)="changeTab(6)">
          <a>Review
            <i *ngIf="requestDetails.ChangeManagement.ChangeState > 6" class="fas fa-check-circle green-icon"></i>
            <i *ngIf="requestDetails.ChangeManagement.ChangeState == 6" class="far fa-hourglass"></i>
          </a>
        </li>

        <li class="nav-item m-r-1 top-tab divLink" [class.active]="changeTabSelected == 7" (click)="changeTab(7)">
          <a>Close
            <i *ngIf="requestDetails.ChangeManagement.ChangeState > 7" class="fas fa-check-circle green-icon"></i>
            <i *ngIf="requestDetails.ChangeManagement.ChangeState == 7" class="far fa-hourglass"></i>
          </a>
        </li>

      </ul>
    </div>
  </div>
  <div id="changeContent" class="row">
    <div class="col">
      <div class="sectionBox">
        <div id="changePlanningTab" *ngIf="changeTabSelected == 2">
          <div class="row">
            <div class="col-sm-2 formHeader p-10">
              Reason for change <span class="text-danger">*</span>
            </div>
            <div class="col-sm p-10">
              <textarea rows="5" id="details.ChangeManagement.Planning_ReasonForChange" [(ngModel)]="requestDetails.ChangeManagement.Planning_ReasonForChange" [disabled]="requestDetails.ChangeManagement.Planning_ReadOnly" class="form-control" style="width: 100%;" spellcheck="true" autocomplete="off"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 formHeader p-10">
              Impact <span class="text-danger">*</span>
            </div>
            <div class="col-sm p-10">
              <textarea rows="5" id="details.ChangeManagement.Planning_Impact" [(ngModel)]="requestDetails.ChangeManagement.Planning_Impact" [disabled]="requestDetails.ChangeManagement.Planning_ReadOnly" class="form-control" style="width: 100%;" spellcheck="true" autocomplete="off"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 formHeader p-10">
              Rollout plan <span class="text-danger">*</span>
            </div>
            <div class="col-sm p-10">
              <textarea rows="5" id="details.ChangeManagement.Planning_RolloutPlan" [(ngModel)]="requestDetails.ChangeManagement.Planning_RolloutPlan" [disabled]="requestDetails.ChangeManagement.Planning_ReadOnly" class="form-control" style="width: 100%;" spellcheck="true" autocomplete="off"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 formHeader p-10">
              Backout / Rollback Plan <span class="text-danger">*</span>
            </div>
            <div class="col-sm p-10">
              <textarea rows="5" id="details.ChangeManagement.Planning_BackoutPlan" [(ngModel)]="requestDetails.ChangeManagement.Planning_BackoutPlan" [disabled]="requestDetails.ChangeManagement.Planning_ReadOnly" class="form-control" style="width: 100%;" spellcheck="true" autocomplete="off"></textarea>
            </div>
          </div>

          <div *ngIf="requestDetails.ChangeManagement.ChangeState != '2' && requestDetails.ChangeManagement.Planning_ReadOnly && !requestDetails.Closed" class="m-t-10 m-b-40">
            <div (click)="editPlan();" class="btn btn-primary float-end">Edit Plan</div>
          </div>

          <div *ngIf="requestDetails.ChangeManagement.ChangeState == '2'" class="m-t-10 m-b-40">
            <div (click)="saveAndCompletePlanning()" class="btn btn-primary float-end">Mark planning as complete</div>
            <div (click)="savePlanning()" class="btn btn-primary float-end">
              <span *ngIf="!isSavingPlan">Save Changes</span>
              <span *ngIf="isSavingPlan">Saving...</span>
            </div>
          </div>
        </div>
        
        <div id="changeScheduleTab" *ngIf="changeTabSelected == 3">
          <div class="row">
            <div class="col-md-6">
                <div class="row pb-3">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="Planning_ScheduledStart">Planned start date</label><span class="text-danger">*</span>
                        </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">
                          <p-calendar  
                            [(ngModel)]="requestDetails.ChangeManagement.Planning_ScheduledStart"
                            [showIcon]="true" 
                            ariaLabel="Date"
                            [showTime]="true" 
                            [iconDisplay]="'input'" 
                            [hourFormat]="12" 
                            inputId="Planning_ScheduledStart"
                            [readonlyInput]="false"
                            [showButtonBar]="false"
                            [hideOnDateTimeSelect]="false"
                            [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"
                            [disabled]="requestDetails.ChangeManagement.Schedule_ReadOnly == '1'"
                          ></p-calendar>
                      </div>
                  </div>
                </div>
            </div>
        
            <div class="col-md-6">
              <div class="row pb-3">
                  <div class="col-md-4">
                      <div class="form-group">
                          <label for="Requested_Date">Requested by date</label><span class="text-danger">*</span>
                      </div>
                  </div>
                  <div class="col-md-8">
                    <div class="form-group">
                      <p-calendar  
                            [(ngModel)]="requestDetails.ChangeManagement.Requested_Date"
                            [showIcon]="true" 
                            ariaLabel="Date"
                            [showTime]="true" 
                            [iconDisplay]="'input'" 
                            [hourFormat]="12" 
                            inputId="Requested_Date"
                            [readonlyInput]="false"
                            [showButtonBar]="false"
                            [hideOnDateTimeSelect]="false"
                            [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"
                            [disabled]="requestDetails.ChangeManagement.Schedule_ReadOnly == '1'"
                          ></p-calendar>
                    </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="row pb-3">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="Planning_ScheduledEnd">Planned end date</label><span class="text-danger">*</span>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                          <p-calendar  
                            [(ngModel)]="requestDetails.ChangeManagement.Planning_ScheduledEnd"
                            [showIcon]="true" 
                            ariaLabel="Date"
                            [showTime]="true" 
                            [iconDisplay]="'input'" 
                            [hourFormat]="12" 
                            inputId="Planning_ScheduledEnd"
                            [readonlyInput]="false"
                            [showButtonBar]="false"
                            [hideOnDateTimeSelect]="false"
                            [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"
                            [disabled]="requestDetails.ChangeManagement.Schedule_ReadOnly == '1'"
                          ></p-calendar>
                        </div>
                    </div>
                </div>
              </div>
      
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="row pb-3">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="Planning_ScheduledEnd">Down time required</label>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                          <input class="form-check-input" type="checkbox" [(ngModel)]="DowntimeRequired" id="Planning_DowntimeRequired" 
                            (change)="handleDowntimeCheckboxChange()" [checked]="requestDetails.ChangeManagement.Planning_DowntimeStart||requestDetails.ChangeManagement.Planning_DowntimeEnd"
                             [disabled]="requestDetails.ChangeManagement.Schedule_ReadOnly == '1'">
                        </div>
                    </div>
                </div>
              </div>
            </div>

            <div *ngIf="DowntimeRequired || requestDetails.ChangeManagement.Planning_DowntimeStart" class="row">
              <div class="col-md-6">
                <div class="row pb-3">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="Planning_DowntimeStart">Down time start</label>
                        </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">
                        <p-calendar  
                        [(ngModel)]="requestDetails.ChangeManagement.Planning_DowntimeStart"
                        [showIcon]="true" 
                        ariaLabel="Date"
                        [showTime]="true" 
                        [iconDisplay]="'input'" 
                        [hourFormat]="12" 
                        inputId="Planning_DowntimeStart"
                        [readonlyInput]="false"
                        [showButtonBar]="false"
                        [hideOnDateTimeSelect]="false"
                        [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"
                        [disabled]="requestDetails.ChangeManagement.Schedule_ReadOnly == '1'"
                      ></p-calendar>
                      </div>
                    </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="DowntimeRequired || requestDetails.ChangeManagement.Planning_DowntimeEnd">
              <div class="col-md-6">
                <div class="row pb-3">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="Planning_DowntimeEnd">Down time end</label>
                        </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">
                        <p-calendar  
                        [(ngModel)]="requestDetails.ChangeManagement.Planning_DowntimeEnd"
                        [showIcon]="true" 
                        ariaLabel="Date"
                        [showTime]="true" 
                        [iconDisplay]="'input'" 
                        [hourFormat]="12" 
                        inputId="Planning_DowntimeEnd"
                        [readonlyInput]="false"
                        [showButtonBar]="false"
                        [hideOnDateTimeSelect]="false"
                        [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"
                        [disabled]="requestDetails.ChangeManagement.Schedule_ReadOnly == '1'"
                      ></p-calendar>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="requestDetails.ChangeManagement.ChangeState != '3' && requestDetails.ChangeManagement.Schedule_ReadOnly && !requestDetails.Closed" class="m-t-10 m-b-40">
            <div (click)="editSchedule();" class="btn btn-primary float-end" *ngIf="userPermissionsSubscription?.Admin || allApprovalStatusCheck()">Edit schedule</div>
          </div>

          <div *ngIf="requestDetails.ChangeManagement.ChangeState == '3'" class="m-t-10 m-b-40">
            <div (click)="saveAndCompleteSchedule()" class="btn btn-primary float-end">Mark schedule as complete</div>
            <div (click)="saveScheduleInfo()" class="btn btn-primary float-end">
              <span *ngIf="!isSavingSchedule">Save Changes</span>
              <span *ngIf="isSavingSchedule">Saving...</span>
            </div>
          </div>
        </div>

        <div id="changeApprovalTab" *ngIf="changeTabSelected == 4">
          <div class="table-responsive">
            <table class="table align-middle tableStyle">
              <thead>
              <tr>
                <th *ngIf="requestDetails.ChangeManagement.ChangeState == '4'" class=""><input [ngModel]="this.approvalIsAllSelected()" (change)="this.approvalToggleAll()"
                                    class="form-check-input" type="checkbox" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault"></label></th>
                <th class="">Name</th>
                <th class="col-sm-3">Email</th>
                <th class="">Board</th>
                <th class="">Approval Status</th>
                <th class="">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let member of this.requestDetails.ChangeManagementCabMembers" class="divLink">
                <td *ngIf="requestDetails.ChangeManagement.ChangeState == '4'"><input [ngModel]="this.selectedMembers.isSelected(member.UserId)"
                           (change)="this.selectedMembers.toggle(member.UserId)" class="form-check-input" type="checkbox"
                           id="filterViewTable">
                  <label class="form-check-label" for="flexCheckDefault"></label></td>
                <td>{{member.Username}}</td>
                <td>{{member.UserEmail}}</td>
                <td>{{member.CabName}}</td>
                <td>
                  <span *ngIf="member.ApprovalStatus == 1"><i class="fas fa-circle"></i> Not Sent</span>
                  <span *ngIf="member.ApprovalStatus == 2"><i class="fas fa-hourglass"></i> Sent</span>
                  <span *ngIf="member.ApprovalStatus == 3"><i class="fa fa-check green-icon"></i> Approved</span>
                  <span *ngIf="member.ApprovalStatus == 4"><i class="fa fa-close" style="color: indianred"></i> Rejected</span>
                </td>
                <td><i *ngIf="requestDetails.ChangeManagement.ChangeState == '4'" (click)="removeCabMember(member.UserId, member.CabId)" class="fas fa-times"></i></td>
              </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="requestDetails.ChangeManagement.ChangeState == '4'" class="m-t-10 m-b-40">
            <div (click)="addCabMembers()" class="btn btn-primary float-end">Add Members</div>
            <button [disabled]="!selectedMembers.selected.length" (click)="requestApproval()" class="btn btn-primary float-end">Request Approval</button>
          </div>

        </div>

        <div id="changeImplementationTab" *ngIf="changeTabSelected == 5">
          <div class="row">

            <div class="row">
                <div class="row pb-3">
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="Actual_StartDate">Actual start</label>
                        </div>
                    </div>
                    <div class="col-md-10">
                      <div class="form-group">
                        <p-calendar  
                            [(ngModel)]="requestDetails.ChangeManagement.Actual_StartDate"
                            [showIcon]="true" 
                            ariaLabel="Date"
                            [showTime]="true" 
                            [iconDisplay]="'input'" 
                            [hourFormat]="12" 
                            inputId="Actual_StartDate"
                            [readonlyInput]="false"
                            [showButtonBar]="false"
                            [hideOnDateTimeSelect]="false"
                            [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"
                            [disabled]="requestDetails.ChangeManagement.ChangeState != '5'"
                          ></p-calendar>
                      </div>
                    </div>
                </div>
                  <div class="row pb-3">
                      <div class="col-md-2">
                          <div class="form-group">
                              <label for="Actual_EndDate">Actual end</label>
                          </div>
                      </div>
                      <div class="col-md-10">
                        <div class="form-group">
                          <p-calendar  
                          [(ngModel)]="requestDetails.ChangeManagement.Actual_EndDate"
                          [showIcon]="true" 
                          ariaLabel="Date"
                          [showTime]="true" 
                          [iconDisplay]="'input'" 
                          [hourFormat]="12" 
                          inputId="Actual_EndDate"
                          [readonlyInput]="false"
                          [showButtonBar]="false"
                          [hideOnDateTimeSelect]="false"
                          [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"
                          [disabled]="requestDetails.ChangeManagement.ChangeState != '5'"
                        ></p-calendar>
                        </div>
                      </div>
                  </div>
            </div>

            <div class="col-sm-2 formHeader p-10">
              Implementation Notes <span class="text-danger">*</span>
            </div>
            <div class="col-sm p-10">
              <textarea rows="5" id="details.ChangeManagement.Implementation_Notes" [(ngModel)]="requestDetails.ChangeManagement.Implementation_Notes" [disabled]="requestDetails.ChangeManagement.ChangeState != '5'" class="form-control" style="width: 100%;" spellcheck="true" autocomplete="off"></textarea>
            </div>
          </div>

          <div *ngIf="requestDetails.ChangeManagement.ChangeState == '5'" class="m-t-10 m-b-40">
            <div (click)="saveAndCompleteImplementation(true);" class="btn btn-primary float-end">Mark as implemented</div>
            <div (click)="saveAndCompleteImplementation(false);" class="btn btn-cancel float-end">Mark as not implemented</div>
            <div (click)="saveImplementation()" class="btn btn-primary float-end">
              <span *ngIf="!isSavingImplementation">Save Changes</span>
              <span *ngIf="isSavingImplementation">Saving...</span>
            </div>
          </div>
        </div>

        <div id="changeReviewTab" *ngIf="changeTabSelected == 6">
          <div class="row">
            <div class="col-sm-2 formHeader p-10">
              Review Notes <span class="text-danger">*</span>
            </div>
            <div class="col-sm p-10">
              <textarea rows="5" id="details.ChangeManagement.Review_Notes" [(ngModel)]="requestDetails.ChangeManagement.Review_Notes" [disabled]="requestDetails.ChangeManagement.ChangeState != '6'" class="form-control" style="width: 100%;" spellcheck="true" autocomplete="off"></textarea>
            </div>
          </div>

          <div *ngIf="requestDetails.ChangeManagement.ChangeState == '6'" class="m-t-10 m-b-40">
            <div (click)="saveAndCompleteReview()" class="btn btn-primary float-end">Mark Review as complete</div>
            <div (click)="saveReview()" class="btn btn-primary float-end">
              <span *ngIf="!isSavingReview">Save Changes</span>
              <span *ngIf="isSavingReview">Saving...</span>
            </div>
          </div>
        </div>

        <div id="changeClosureTab" *ngIf="changeTabSelected == 7">
          <div class="row">
            <div class="col-sm-2 formHeader p-10">
              Closure Notes <span class="text-danger">*</span>
            </div>
            <div class="col-sm p-10">
              <textarea rows="5" id="details.ChangeManagement.Closure_Notes" [(ngModel)]="requestDetails.ChangeManagement.Closure_Notes" [disabled]="requestDetails.ChangeManagement.ChangeState != '7'" class="form-control" style="width: 100%;" spellcheck="true" autocomplete="off"></textarea>
            </div>
          </div>

          <div *ngIf="requestDetails.ChangeManagement.ChangeState == '7'" class="m-t-10 m-b-40">
            <div (click)="saveAndCompleteClosure()" class="btn btn-primary float-end">Close Change</div>
            <div (click)="saveClosure()" class="btn btn-primary float-end">
              <span *ngIf="!isSavingclose">Save Changes</span>
              <span *ngIf="isSavingclose">Saving...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
