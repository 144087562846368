<div class="col">
  <div class="threadItem maxImg" [ngClass]="getClassName()">
    <span>
      <i *ngIf="threadItem.ThreadType == 3" class="fas fa-lock m-r-10"></i>
      <strong>{{this.getFromText()}}</strong>
      <span class="float-end"><i class="far fa-clock m-r-5"></i> <app-friendly-time
          [utcTimeData]="threadItem.DateTimeUtc"
          [timeFormat]="instanceParams.preferredDateFormat"></app-friendly-time></span>

    </span>
    
    <!-- For taskcomments -->
    <div *ngIf="threadItem.ThreadType == 5" class="taskcomment">
      <a *ngIf="threadItem.RequestId && !isInDialog" (click)="editTask(threadItem.TaskId,threadItem.RequestId,threadItem.TypeId)">#T{{threadItem.TaskId}}</a>
    </div>

    <!-- Conditionally display the text area for editing -->
    <div *ngIf="threadItem.isEditing">
      <quill-editor [(ngModel)]="threadItem.BodyText" (ngModelChange)="onTextChange()" style="background-color: white;"> </quill-editor>
      <!-- For ticket comments -->
      <span *ngIf="threadItem.ThreadType == 3">
        <button class="btn btn-primary btn-sm m-t-10" (click)="saveEdit(threadItem)" [disabled]="!isTextChanged || isSaving">Save</button>
        <button class="btn btn-primary btn-sm m-t-10" (click)="cancelEdit(threadItem)">Cancel</button>
      </span>
      <!-- For task comments -->
       <span *ngIf="threadItem.ThreadType == 5 && threadItem.DateTimeUtc">
        <button class="btn btn-primary btn-sm m-t-10" (click)="saveEditTaskComment(threadItem)" [disabled]="!isTextChanged ||  isSavingTaskComment">Save</button>
        <button class="btn btn-primary btn-sm m-t-10" (click)="cancelEditTaskComment(threadItem)">Cancel</button>
       </span>
       <!-- For task comments (Temperary)-->
       <span *ngIf="threadItem.ThreadType == 5 && !threadItem.DateTimeUtc">
        <button class="btn btn-primary btn-sm m-t-10" (click)="saveEditTaskCommentTemp(threadItem)">Save</button>
        <button class="btn btn-primary btn-sm m-t-10" (click)="cancelEditTaskComment(threadItem)">Cancel</button>
       </span>
      
    </div>

    <!-- Show the message when not in edit mode -->
    <div *ngIf="!threadItem.isEditing" [innerHTML]="getSafeHtml(threadItem.BodyText)"></div>


    <!-- <div [innerHTML]="getSafeHtml(threadItem.BodyText)"></div> -->
    <!-- Attach the attachmentfiles -->
    <div class="attachments-container" *ngFor="let attachment of threadItemAttachments">
      <span *ngIf="threadItem.Id == attachment.ThreadItemId" (click)="downloadAttachment(attachment.AttachmentId)"
        class="btn btn-outline-secondary btn-sm attachmentButton m-r-5 m-t-10">
        <i class="fas fa-file m-r-5"></i>
        <span>{{attachment.AttachmentName}}</span>
      </span>
    </div>

    <span class="float-end" style="color: darkgrey;">
      <i class="far fa-stopwatch m-r-5"></i> {{this.secondsToHms(threadItem.TimeTrackingSeconds)}}
    </span>

    <ng-container *ngIf="getClassName() === 'comment' && instanceParams.userName === threadItem.AgentName && !isRequestClosed">
      <span class="float-end icon-hover" (click)="deleteComment(threadItem)">
        <i class="far fa-trash-alt m-r-10"></i>
      </span>

      <span class="float-end icon-hover" (click)="editMessage(threadItem)">
        <i class="fas fa-pen m-r-10"></i>
      </span>
    </ng-container>

    <!-- Edit and delete for the task comments -->
    <ng-container *ngIf="getClassName() === 'taskComment' && instanceParams.userName === threadItem.AgentName && !isRequestClosed">
      <span class="float-end icon-hover" (click)="deleteTaskComment(threadItem)">
        <i class="far fa-trash-alt m-r-10"></i>
      </span>

      <span class="float-end icon-hover" (click)="editTaskComment(threadItem)">
        <i class="fas fa-pen m-r-10"></i>
      </span>
    </ng-container>
    <div>{{threadItem.index}}</div>

    <!-- Edit and delete for the task comments when not from databse -->
    <ng-container *ngIf="getClassName() === 'taskComment' && threadItem.notsaved !isRequestClosed">

      <span class="float-end icon-hover" (click)="removeCommentItem(threadItem.Index)">
        <i class="far fa-trash-alt m-r-10"></i>
      </span>

      <span class="float-end icon-hover" (click)="editTaskComment(threadItem.Index)">
        <i class="fas fa-pen m-r-10"></i>
      </span>
    </ng-container>
  </div>
</div>