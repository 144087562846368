<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedData?.Id ? 'Edit Task' : 'Create Task' }}</h3>
  </div>
  <hr>
  <div class="container p-0">

<!-- Task ID -->
<div class="row" *ngIf="loadedData?.Id">
    <div class="col-sm-2 formHeader alignMiddle p-10">
      Task ID
    </div>
    <div class="col-sm p-10">
      <div class="task-id-display">
        T{{ loadedData.Id }}
      </div>
    </div>
  </div>

    <!-- Task Name -->
    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Task <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.TaskName" 
               type="text" 
               class="form-control" 
               placeholder="Enter Task Name" 
               autocomplete="off">
      </div>
    </div>

    <!-- Instructions -->
    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Instructions
      </div>
      <div class="col-sm p-10">
        <textarea #instructionsTextarea
                  [(ngModel)]="loadedData.Instructions" 
                  class="form-control auto-adjust-textarea" 
                  placeholder="Enter Instructions"></textarea>
      </div>
    </div>

    <!-- Group -->
    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Group
      </div>
      <div class="col-sm p-10">
        <ng-select 
          [items]="groups"
          [(ngModel)]="loadedData.GroupId"
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [clearable]="true"
          (change)="onGroupChange($event)"
          [clearable]="true"
          placeholder="Select Group">
        </ng-select>
      </div>
    </div>

    <!-- Assigned to -->
    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Assigned to
      </div>
      <div class="col-sm p-10">
        <ng-select 
          [items]="agents"
          [(ngModel)]="loadedData.AgentId"
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [clearable]="true"
          placeholder="Select Agent">
        </ng-select>
      </div>
    </div>

    <!-- Status -->
    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Status
      </div>
      <div class="col-sm p-10">
        <ng-select
          [items]="statuses"
          bindLabel="label"
          bindValue="value"
          [(ngModel)]="loadedData.Completed"
          [clearable]="false"
          [disabled]="!this.loadedData?.Id"
          placeholder="Select Status">
        </ng-select>
      </div>
    </div>

    <div class="m-t-20">
      <button (click)="save()" class="btn btn-primary">Save</button>
    </div>
  </div>

   <!-- Comments -->
   <div class="row">
    <div class="col-sm-2 formHeader alignMiddle p-10">
      Comment
    </div>
    <div class="col-sm p-10">
      <quill-editor 
        id="taskInstructions" 
        [(ngModel)]="loadedData.Comment" 
        [styles]="{height: '150px'}"
        placeholder="">
      </quill-editor>
      <div class="m-t-20">
        <button (click)="addComment()" class="btn btn-primary">Add Comment</button>
      </div>
    </div>
  </div>
  
  <!-- Display Comments -->
  <div class="row" *ngIf="loadedData.Id">
    <div class="col-sm-12">
    <app-request-thread-item
    *ngFor="let threadItem of comment" 
     [threadItem]="threadItem"
     [isInDialog]="true"
     [isRequestClosed]="requestDetailsClosed"
     (refreshData)="getComment(threadItem.TaskId)">
   </app-request-thread-item>
    </div>
  </div>

  <div class="row" >
    <div class="col-sm-12">
      <ng-container *ngFor="let threadItem of commentArray" >
          <app-request-thread-item
          *ngIf="threadItem.notsaved"
          [threadItem]="threadItem"
          [isRequestClosed]="requestDetailsClosed">
        </app-request-thread-item>
  </ng-container>
    </div>
  </div>

</app-blade-right>