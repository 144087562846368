import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class TasksApiService {

  apiUri = '/api/tasks'

  constructor(private apiService: ApiService) {
  }

  create(data) {
    return this.apiService.post(this.apiUri, data);
  }

  listMyTasks(page: number, perPage: number) {
    let params = {
      'page': page ?? '',
      'perPage': perPage ?? ''
    };
    return this.apiService.get(`${this.apiUri}/my-tasks`, params);
  }
  listAllTasks() {
    return this.apiService.get(`${this.apiUri}/all-tasks`);
  }

  listRequestTasks(requestId) {
    return this.apiService.get(`${this.apiUri}/request/${requestId}`);
  }

  markComplete(id) {
    return this.apiService.put(`${this.apiUri}/${id}/mark-complete`);
  }

  markPending(id) {
    return this.apiService.put(`${this.apiUri}/${id}/mark-pending`);
  }

  assignToAgent(id, agentId) {
    return this.apiService.put(`${this.apiUri}/${id}/assign/${agentId}`);
  }

  unassignAgent(id) {
    return this.apiService.put(`${this.apiUri}/${id}/unassign`);
  }

  assignToGroup(id, groupId) {
    return this.apiService.put(`${this.apiUri}/${id}/assign-group/${groupId}`);
  }

  delete(id) {
    return this.apiService.delete(`${this.apiUri}/${id}`);
  }
 
  updateTask(taskId: string, data: any) {
    return this.apiService.put(`${this.apiUri}/${taskId}`, data);
  }
}
