

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef,Injectable} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminCustomFieldService} from "../../../../../../services/api/admin/admin-custom-fields-api/admin-custom-fields-api.service";
import { HttpClient } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Component({
  selector: 'app-admin-custom-fields-create-edit-dialog',
  templateUrl: './admin-custom-fields-create-edit-dialog.component.html'
})
@Injectable({
  providedIn: 'root'
})
export class AdminCustomFieldsComponentDialog implements Dialog {

  @Input() data; // {id: 122, requestTypeId: 12, isCmdbEnabled: true}
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  isSaving = false;

  loadedId = null;
  requestTypeId = null;

  loadedData;

  customFieldTypeOptions = [
    { id: 1,  name: 'Single Textbox'},
    { id: 2,  name: 'Multiple Choice'},
    { id: 3,  name: 'Date'},
  ];

  selectedLevel1Id = null;
  selectedLevel2Id = null;
  selectedLevel3Id = null;

  addLevel1Value;
  addLevel2Value;
  addLevel3Value;

  constructor(
    private apiAdminCustomFieldService: ApiAdminCustomFieldService,
    private customFieldService: ApiAdminCustomFieldService
  ) {}


  ngOnInit(): void {
    this.requestTypeId = this.data.requestTypeId;

    if(this.data.id !== null){
      this.loadedId = this.data.id;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: ''
      }

      this.isLoading = false;

    }
  }

  filterOptions(level, parentOptionId){
    if (this.loadedData.Options === undefined) {
      return;
    }
    return this.loadedData.Options.filter(option => option.Level == level && option.ParentCustomFieldId == parentOptionId);
  }

  createOption(value, level, parentId){
    this.apiAdminCustomFieldService.createCustomFieldOption(this.requestTypeId, this.loadedId, value, level, parentId).then(response => {
      this.getData();
    })

    this.addLevel1Value = null;
    this.addLevel2Value = null;
    this.addLevel3Value = null;

  }

  isCmdbEnabled(){
    console.log(this.data.isCmdbEnabled)
    if (this.data && this.data.hasOwnProperty('isCmdbEnabled') && this.data.isCmdbEnabled) {
      return true;
  }
    return false;
  }

  deleteOption(optionId){
    this.apiAdminCustomFieldService.deleteCustomFieldOption(this.requestTypeId, this.loadedId, optionId).then(response => {
      this.getData();
    })
  }

  deleteSelectedOptions() {
    const selectedOptions = this.loadedData.Options.filter(option => option.selected);
    const deletePromises = selectedOptions.map(option =>
      this.apiAdminCustomFieldService.deleteCustomFieldOption(this.requestTypeId, this.loadedId, option.Id)
        .catch(error => {
          console.error(`Failed to delete option: ${error}`);
          return error; // Continue with other deletions even if one fails

        })

    );

    Promise.all(deletePromises).then(results => {
      console.log("Bulk delete results:", results);
      this.getData(); // Refresh data after operations
    });
  }
  moveOptionUp(optionId){
    this.apiAdminCustomFieldService.moveUpCustomFieldOption(this.requestTypeId, this.loadedId, optionId).then(response => {
      this.getData();
    })
  }




  moveOptionDown(optionId){
    this.apiAdminCustomFieldService.moveDownCustomFieldOption(this.requestTypeId, this.loadedId, optionId).then(response => {
      this.getData();
    })
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCustomFieldService.get(this.requestTypeId, this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){

    this.isSaving=true;

    if(this.loadedId){
      if (this.loadedData.Shared){
        this.loadedData.RequestTypeId=null
      }else{
        this.loadedData.RequestTypeId=this.requestTypeId
      }
      this.apiAdminCustomFieldService.update(this.requestTypeId, this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
      .catch(error => {
        this.isSaving=false;
        console.error("An error occurred while updating custom field:", error);
      });
    } else {
      this.apiAdminCustomFieldService.create(this.requestTypeId, this.loadedData)
      .then(response => {
        this.remove.emit("Saved");
      })
      .catch(error => {
        this.isSaving=false;
        console.error("An error occurred while creating custom field:", error);
      });
    }

  }
}
