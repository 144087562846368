<div class="SDeskSidePanel-GreyBackground"></div>
<app-blade-right size="blade-large">
    <div class="p-10 p-b-0">
        <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
        <h3>Create Scheduled Report</h3>
    </div>
    <hr>
    <div class="container p-0">
        <div class="col-sm p-10">
            <input [(ngModel)]="loadedData.ReportingEnabled" class="form-check-input" id="Enabled" type="checkbox"><label for="Enabled" class="p-l-10">Enable Schedule Reports</label>

        </div>
        <div *ngIf="loadedData.ReportingEnabled">
            <div class="row sdesk-grey-row">
                <div class="col-sm-2 formHeader alignMiddle p-10">
                    Frequency <span class="text-danger">*</span>
                </div>
                <div class="col-sm p-10">
                    <ng-select [items]="frequencyOptions" bindLabel="name" bindValue="id" [(ngModel)]="loadedData.Frequency" [clearable]="false">
                    </ng-select>
                </div>
            </div>

            <div class="row sdesk-grey-row">
                <div class="col-sm-2 formHeader alignMiddle p-10">
                    Start On  <span class="text-danger">*</span>
                    <div>[UTC time]</div>
                </div>
                
                <div class="col-sm p-10">

                    <!-- <mat-form-field appearance="fill">
                        <input  matInput style="display: none;" [ngxMatDatetimePicker]="startDateTimePicker" [(ngModel)]="loadedData.StartDateTime" name="startDateTime">
                        <ngx-mat-datepicker-toggle matSuffix [for]="startDateTimePicker"></ngx-mat-datepicker-toggle>
                        <span>{{ loadedData.StartDateTime | date:"yyyy-MM-dd, h:mm a" }}</span>
                        <ngx-mat-datetime-picker [touchUi]="true" #startDateTimePicker></ngx-mat-datetime-picker>
                    </mat-form-field> -->
                    <p-calendar  
                    [(ngModel)]="loadedData.StartDateTime"
                    [showIcon]="true" 
                    ariaLabel="Date"
                    [showTime]="true" 
                    [iconDisplay]="'input'" 
                    [hourFormat]="12" 
                    inputId="startDateTime"
                    [readonlyInput]="false"
                    [showButtonBar]="false"
                    [hideOnDateTimeSelect]="false"
                    [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"
                  ></p-calendar>
                    
                </div>
            </div>

            <!-- Filter Group -->
            <div class="row sdesk-grey-row">
                <div class="col-2 formHeader alignMiddle p-10">
                    Group <span class="text-danger">*</span>
                </div>
                <div class="col-sm p-10">

                    <app-sdesk-multi-select [data]="filterOptions.group" [(selectedData)]="loadedData.ReportGroupValues"></app-sdesk-multi-select>


                </div>
            </div>

            <!-- Filter Agent -->

            <div class="row sdesk-grey-row">
                <div class="col-2 formHeader alignMiddle p-10">
                    Agent <span class="text-danger">*</span>
                </div>
                <div class="col-sm p-10">
                    <!-- <app-sdesk-multi-select [data]="filterOptions.agent" [(selectedData)]="filterData.AgentValues"></app-sdesk-multi-select> -->

                    <app-sdesk-multi-select [data]="filterOptions.agent" [(selectedData)]="loadedData.ReportAgentValues"></app-sdesk-multi-select>


                </div>
            </div>


        </div>
        <div class="m-t-20">
            <div (click)="save()" class="btn btn-primary"> Save</div>
        </div>
    </div>

</app-blade-right>