import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class ReportingApiService {

  constructor(private apiService: ApiService) { }

  getReportingData(dateFrom, dateTo, filterTypeIds, filterGroupIds, filterAgentIds, filterStatusIds, filterCustomerIds){

    let params = {
      'datefrom' : dateFrom,
      'dateto' : dateTo,
      'DownloadCsv' : 0
    }

    let body = {
      filter: {
        type: filterTypeIds,
        group: filterGroupIds,
        agent: filterAgentIds,
        status: filterStatusIds,
        customer: filterCustomerIds
      }
    }

    return this.apiService.post(`/api/itil/reportingdashboard`, body, params)

  }

  downloadReportingData(dateFrom, dateTo, filterTypeIds, filterGroupIds, filterAgentIds, filterStatusIds, filterCustomerIds, withTasks=false){
    let params = {
      'datefrom' : dateFrom,
      'dateto' : dateTo,
      'DownloadCsv' : 1
    }

    if(withTasks){
      params['IncludeTasks'] = 1;
    }

    let body = {
      filter: {
        type: filterTypeIds,
        group: filterGroupIds,
        agent: filterAgentIds,
        status: filterStatusIds,
        customer: filterCustomerIds
      }
    }

    return this.apiService.post(`/api/itil/reportingdashboard/download`, body, params);
  }

  getTicketList(dateFrom, dateTo, filterTypeIds, filterGroupIds, filterAgentIds, filterStatusIds, filterCustomerIds, withTasks=false,page:number,perPage:number, orderById = 29, orderByAsc=true,AddPagination=false){
    let params = {
      'datefrom' : dateFrom,
      'dateto' : dateTo,
      'ReturnTickets' : 1 ,
      // Newly added
      'page': page ?? 1,
      'perPage': perPage ?? 50,
      'orderby':orderById,
      'ascending':orderByAsc
    }

    if(withTasks){
      params['IncludeTasks'] = 1;
    }

    let body = {
      filter: {
        type: filterTypeIds,
        group: filterGroupIds,
        agent: filterAgentIds,
        status: filterStatusIds,
        customer: filterCustomerIds
      }
    }

    return this.apiService.post(`/api/itil/reportingdashboard`, body, params);

  }

  getTimeElapsed(dateFrom, dateTo){
    let params = {
      'datefrom' : dateFrom,
      'dateto' : dateTo
    }

    return this.apiService.post(`/api/itil/reporting-time-tracking`, null, params);

  }

  getTimeElapsedCustomer(dateFrom, dateTo){
    let params = {
      'datefrom' : dateFrom,
      'dateto' : dateTo
    }

    return this.apiService.post(`/api/itil/reporting-time-tracking-customer`, null, params);

  }

  getAllReportingFilters(){
    return this.apiService.get(`/api/itil/report-filter`);
  }

  getReportingFilterDetails(id){
    return this.apiService.get(`/api/itil/report-filter/${id}`);
  }

  saveReportingFilter(data){
    return this.apiService.post(`/api/itil/report-filter`, data);
  }

  deleteReportingFilter(id){
    return this.apiService.delete(`/api/itil/report-filter/${id}`);
  }

  getAllSurvey(){
    return this.apiService.get(`/api/survey`);
  }

  getSurveyDetails(id){
    return this.apiService.get(`/api/survey/`+id);
  }

  getSurveyData(dateFrom, dateTo, filterTypeIds, filterGroupIds, filterAgentIds, filterStatusIds, filterCustomerIds,page:number,perPage:number,surveyFilterTicketsRating:number){

    let params = {
      'datefrom' : dateFrom,
      'dateto' : dateTo,
      // for pagination
      'page': page ?? 1,
      'perPage': perPage ?? 50
    }

    let body = {
      filter: {
        type: filterTypeIds,
        group: filterGroupIds,
        agent: filterAgentIds,
        status: filterStatusIds,
        customer: filterCustomerIds
      },
      surveyRating:{
        rate:surveyFilterTicketsRating
      }
    }

    return this.apiService.post(`/api/survey-filter`, body, params)

  }

}
