
import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class AdminCustomSurveyService  {

  constructor(private apiService: ApiService) {
  }

  listAll(typeId) {
    return this.apiService.get(`/api/survey/surveycustomfields/${typeId}`);
  }

  get(id) {
    return this.apiService.get(`/api/survey/surveycustomfields/detail/${id}`);
  }

  delete(id) {
    return this.apiService.delete(`/api/survey/surveycustomfields/detail/${id}`);
  }

  create(data) {
    return this.apiService.post('/api/survey/surveycustomfields', data);
  }

  update(id, data) {
    return this.apiService.put(`/api/survey/surveycustomfields/detail/${id}`, data);
  }

  getTriggerDelay(RequestTypeId){
    return this.apiService.get(`/api/survey/triggerdelay/${RequestTypeId}`);
  }

  updateTriggerDelay(RequestTypeId,data){
    return this.apiService.put(`/api/survey/triggerdelay/${RequestTypeId}`,data);
  }

  getSurveyReminder(RequestTypeId){
    return this.apiService.get(`/api/survey/surveyreminder/${RequestTypeId}`);
  }

  updateSurveyReminder(RequestTypeId, data){
    return this.apiService.put(`/api/survey/surveyreminder/${RequestTypeId}`,data);
  }

  getSurveyRatio(RequestTypeId) {
    return this.apiService.get(`/api/survey/surveyratio/${RequestTypeId}`);
  }
  
  updateSurveyRatio(RequestTypeId, data) {
    return this.apiService.put(`/api/survey/surveyratio/${RequestTypeId}`, data);
  }

}



