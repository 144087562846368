export class SdeskHelper {
  public static DASHBOARD = 'DASHBOARD';
  public static TICKETING = 'TICKETING';
  public static REPORTING = 'REPORTING';

    public static isNotNullOrEmpty(value: any): boolean {
      if (typeof value === 'string') {
        return value !== undefined && value !== null && value !== '';
      } else if (typeof value === 'number' || Object.prototype.toString.call(value) === '[object Date]'
        || Object.prototype.toString.call(value) === '[object File]') {
        return value !== undefined && value !== null;
      }
      return value !== undefined && value !== null && Object.keys(value).length !== 0;
    }

    public static isNotEmpty(array): boolean {
      return this.isNotNullOrEmpty(array) && array.length > 0;
    }

    public static getColName(colId: number, screen: string): string {
        let colName = '';
        console.log('screen = ', screen);
        switch(colId){  
          case 2:  
            colName = 'Status';
            break; 
          case 3:  
            colName = 'Priority';
            break; 
          case 4:  
            colName = 'Agent';
            break; 
          case 8:  
            if (screen === this.TICKETING) {
              colName = 'Created';
            } else {
              colName = 'DateTimeCreated';
            }
            break; 
          case 9:  
            colName ='Subject';
            break; 
          case 10:
            if (screen === this.DASHBOARD) {
              colName = 'Requester';
            } else if (screen === this.REPORTING) {
              colName = 'RequesterName';
            } else {
              colName = 'Requester';
            }
            break; 
          case 11:
            if (screen === this.DASHBOARD) {
              colName = 'LocalRequestId';
            } else {
              colName = 'Id';

            } 
            break; 
          case 12:  
            colName = 'Responded';
            break; 
          case 13:  
            colName = 'Resolved';
            break; 
          case 14: 
            if (screen === this.TICKETING) {
              colName = 'Updated';
            } else {
              colName = 'DateTimeUpdated';
            }
            break; 
          case 15:  
            colName = 'Response Due';
            break; 
          case 16: 
            if (screen === this.TICKETING) {
              colName = 'Resolution Due';
            } else {
              colName = 'SlaResolutionOverdue';
            }
            break; 
          case 17:  
            colName = 'Survey Result';
            break; 
          case 18:  
            colName = 'Survey Status';
            break; 
          case 19:  
            colName = 'Service';
            break;
          case 20:  
            colName = 'Group';
            break; 
          case 21:  
            colName = 'Customer';
            break; 
          case 22:  
            colName = 'Impact';
            break; 
          case 23:  
            colName = 'Urgency';
            break;
          case 24:  
            colName = 'Resolution Code';
            break;
          case 25:  
            colName = 'Requester Responded';
            break;
          case 26:  
            colName = 'Last Status Change';
            break;
          case 27:  
            colName = 'Change State';
            break;
          case 28:  
            if (screen === this.DASHBOARD) {
              colName = 'RequestTypeName';

            } else if (screen === this.REPORTING) {
              colName = 'RequestType';

            } else {
              colName = 'Type';

            }
            break; 
          case 29:  
            if (screen === this.DASHBOARD) {
              colName = 'NextSlaDueDateTime';
            } else if (screen === this.REPORTING) {
              colName = 'SlaResponseOverdue';
            } else {
              colName = 'Sla';
            }
            break;
          case 30:  
            colName = 'Asset Type';
            break;
          case 31:  
            colName = 'Serial Number';
            break;
          case 32:  
            colName = 'Imei';
            break;
          case 33:  
            colName = 'Manufacturer';
            break;
          case 34:  
            colName = 'Model';
            break;
          case 35:  
            colName = 'Cost';
            break;
          case 36:  
            colName = 'Planning Scheduled Start';
            break;
          case 37:  
            colName = 'Planning Scheduled End';
            break;
          case 38:  
            colName = 'Requested By Date';
            break;
          case 39:  
            colName = 'Planning Downtime Start';
            break;
          case 40:  
            colName = 'Planning Downtime End';
            break;
          case 41:  
            colName = 'Requester Email';
            break; 
          case 42:  
            colName = 'Approval Status';
            break;
          case 44:  
            colName = 'TotalTimeTrackingSeconds';
            break;
          default:  
            colName = '';
            break; 
          }  
        return colName;
    }
        
    public static getColId(column: string): number {
    let code = 0;
    switch(column){
        case 'Status':  
        code = 2;
        break; 
        case 'Priority':  
        code = 3;
        break; 
        case 'Agent':  
        code = 4;
        break; 
        case 'DateTimeCreated':  
        code = 8;
        break; 
        case 'Created':  
        code = 8;
        break; 
        case 'Subject':  
        code = 9;
        break; 
        case 'Requester':  
        code = 10;
        break; 
        case 'RequesterName':  
        code = 10;
        break; 
        case 'Id':  
        code = 11;
        break; 
        case 'LocalRequestId':  
        code = 11;
        break;
        case 'Responded':  
        code = 12;
        break; 
        case 'Resolved':  
        code = 13;
        break; 
        case 'Updated':  
        code = 14;
        break; 
        case 'DateTimeUpdated':  
        code = 14;
        break; 
        case 'Response Due':  
        code = 15;
        break; 
        case 'SlaResolutionOverdue':  
        code = 16;
        break; 
        case 'Resolution Due':  
        code = 16;
        break; 
        case 'Survey Result':  
        code = 17;
        break; 
        case 'Survey Status':  
        code = 18;
        break; 
        case 'Service':  
        code = 19;
        break; 
        case 'Group':  
        code = 20;
        break; 
        case 'Customer':  
        code = 21;
        break; 
        case 'Impact':  
        code = 22;
        break; 
        case 'Urgency':  
        code = 23;
        break; 
        case 'Resolution Code':  
        code = 24;
        break; 
        case 'Requester Responded':  
        code = 25;
        break; 
        case 'Last Status Change':  
        code = 26;
        break; 
        case 'Change State':  
        code = 27;
        break; 
        case 'RequestTypeName':  
        code = 28;
        break; 
        case 'RequestType':  
        code = 28;
        break;
        case 'Type':  
        code = 28;
        break;
        case 'NextSlaDueDateTime':  
        code = 29;
        break; 
        case 'SlaResponseOverdue':  
        code = 29;
        break;
        case 'Sla':  
        code = 29;
        break;
        case 'Asset Type':  
        code = 30;
        break;
        case 'Serial Number':  
        code = 31;
        break;
        case 'Imei':  
        code = 32;
        break;
        case 'Manufacturer':  
        code = 33;
        break;
        case 'Model':  
        code = 34;
        break;
        case 'Cost':  
        code = 35;
        break;
        case 'Planning Scheduled Start':  
        code = 36;
        break;
        case 'Planning Scheduled End':  
        code = 37;
        break;
        case 'Requested By Date':  
        code = 38;
        break;
        case 'Planning Downtime Start':  
        code = 39;
        break;
        case 'Planning Downtime End':  
        code = 40;
        break;
        case 'Requester Email':  
        code = 41;
        break;
        case 'Approval Status':  
        code = 42;
        break;
        case 'TotalTimeTrackingSeconds':  
        code = 44;
        break; 
        default:  
        code = 0;
        break; 
        }  
        return code;
    }

    public static execSortStoreRoutine(sname: string, orderby: number, ascending: number) {
        let sort_obj = { orderby: 0, ascending: 0 };
        sort_obj.orderby = orderby;
        sort_obj.ascending = ascending;
        localStorage.setItem(sname, JSON.stringify(sort_obj));
    }
 
    public static sortInt(viewData: any, orderTableBy: any) {
        viewData.sort((n1,n2) => {
          let compareA = n1[orderTableBy] ?? 0;
          let compareB = n2[orderTableBy] ?? 0;
          if (compareA > compareB) {
              return 1;
          }
          if (compareA < compareB) {
              return -1;
          }
          return 0;
        })
    }

    public static sortNonInt(viewData: any, orderTableBy: any) {
        viewData.sort((a, b) => {
          let compareA = a[orderTableBy] ?? '';
          let compareB = b[orderTableBy] ?? '';
          return compareA.localeCompare(compareB);
        })
    }

    public static initSortStorage(sname) {
        let sort_obj = {orderby: 0, ascending: 1};
        localStorage.setItem(sname, JSON.stringify(sort_obj));
    }

    public static getSelectedTicketOption(): string {
      let selected = 'all';
      let ticketOption_my_tickets = JSON.parse(localStorage.getItem('ticketOption_my_tickets'));
      if (ticketOption_my_tickets !== null && ticketOption_my_tickets.defaultTicketOption) {
        selected = ticketOption_my_tickets.defaultTicketOption;
      }    
      return selected;
    }

    public static initReportFilterStorage(res: any) {
      this.removeDatesFilter();
      this.removeReportFilter();
      let filter_obj = {res: res, filterId: res.Id, dateSaved: new Date()};
      localStorage.setItem('reporting_filter', JSON.stringify(filter_obj));
    }

    public static saveDatesFilter(selectedReportPeriod: string, dateFrom: any, dateTo, filters: any) {
      this.removeDatesFilter();
      this.removeReportFilter();
      let filter_obj: any = {selectedReportPeriod: selectedReportPeriod, dateFrom: dateFrom, dateTo: dateTo, filters: filters};
      localStorage.setItem('reporting_filter_dates', JSON.stringify(filter_obj, this.getCircularReplacer()));
    }
    
    public static  getCircularReplacer() {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return; // Omit circular reference
          }
          seen.add(value);
        }
        return value;
      };
    }
    
    public static getDatesFilter(): any {
      return JSON.parse(localStorage.getItem('reporting_filter_dates'));
    }

    public static getReportingFilter(): any {
      return JSON.parse(localStorage.getItem('reporting_filter'));
    }

    public static removeDatesFilter() {
      localStorage.removeItem('reporting_filter_dates');
    }

    public static removeReportFilter() {
      localStorage.removeItem('reporting_filter');
    }

    public static getReportFilter(): object {
      return JSON.parse(localStorage.getItem('reporting_filter'));
    }

    // Function to store pagination for custom filters, requestViews(active,unassigned,overdue,due today,opened today,resolved today)
    public static storePaginationData(sname: string, idKey: string, idValue: number|string, view: string | null, perPage: number, page: number) {
      
      // Retrieve the current list of filters 
      const filters = JSON.parse(localStorage.getItem(sname)) || [];

       // Create the new filter object
      const paginationResult = { [idKey]: idValue, view, perPage, page };
    
      // Check if the filterId already exists
      const existingFilterIndex = filters.findIndex((filter: any) => filter[idKey] === idValue && (!view || filter.view === view));
    
      if (existingFilterIndex !== -1) {
        // Update existing
        filters[existingFilterIndex] = paginationResult; 
      } else {
        // Add new entry
        filters.push(paginationResult); 
      }
    
       // Store the updated array back to localStorage
      localStorage.setItem(sname, JSON.stringify(filters));
    }

    // Function to get filter results from localStorage
    public static getFilterResults(sname: string) {
      return JSON.parse(localStorage.getItem(sname)) || [];
    }

    // Function to find a specific filter by filterId
    public static getFilterById(sname: string, filterId: number) {
      const filters = this.getFilterResults(sname);
      return filters.find((filter: any) => filter.filterId === filterId);
    }    

    // Function to get requestView results from localStorage
    public static getViewResults(sname: string) {
      return JSON.parse(localStorage.getItem(sname)) || [];
    }

    // Function to find a specific requestview by Id
    public static getViewById(sname: string, requestTypeId: number, view: string) {
      const filters = this.getViewResults(sname);
      return filters.find((filter: any) => filter.requestTypeId === requestTypeId && filter.view === view);
    }

    // Function to find a specific task view
    public static getViewBytaskId(sname: string, view: string): { taskId: number | null; perPage: number; page: number }[] {
      const storedData = localStorage.getItem(sname);
  
      if (storedData) {
          const TaskView_pagination = JSON.parse(storedData);
          const lastItemIndex = TaskView_pagination.length - 1;
          const perPage = TaskView_pagination[lastItemIndex].perPage;
          const page = TaskView_pagination[lastItemIndex].page;
  
          console.log(perPage, page);
          return [{ taskId: null, perPage: perPage, page: page }];
      } else {
          console.log('No data found in local storage');
          return [{ taskId: null, perPage: 50, page: 1 }];
      }
  }

  public static limitString(arrayObj, maxChars, isIntelligent) {
    if (arrayObj && arrayObj.length > 0) {

      //copy 
      arrayObj.forEach(task => 
        task.InstructionsCopy = (' ' + task.Instructions).slice(1));
      if (isIntelligent) {
        arrayObj.forEach(task => task.Instructions = this.lmtStrIntelligent(task.Instructions, maxChars));
      } else {
        arrayObj.forEach(task => task.Instructions = this.lmtStr(task.Instructions, maxChars));

      }
    }

  }
  
  public static lmtStr(str, maxChars) {
    if (str && str.length > maxChars) {
      return str.slice(0, maxChars) + '...';
    }
    return str;
  }

  public static lmtStrIntelligent(str, maxChars) {
    // If the string is already within the limit, return it as is.
    if (str.length <= maxChars) {
      return str;
    }
  
    // Otherwise, cut the string to the max character limit.
    let truncated = str.slice(0, maxChars);
  
    // Find the last space in the truncated string to avoid cutting a word in half.
    const lastSpaceIndex = truncated.lastIndexOf(' ');
    
    // If a space was found and it's not the very first character, use that index.
    if (lastSpaceIndex > 0) {
      truncated = truncated.slice(0, lastSpaceIndex);
    }
  
    return truncated + '...';
  }
}
