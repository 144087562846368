<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
 
<!---  ///////old table commented for reference//-->
<!-- <div class="col-12 sectionBox p-40" style="padding: 10px">
 
  <div class="row m-b-5">
    <div class="col-3" style="padding-left: 10px; font-size: 16px">Task</div>
    <div class="col" style="padding-left: 10px; font-size: 16px">Instructions</div>
    <div class="col-2" style="padding-left: 10px; font-size: 16px">Assigned To</div>
    <div *ngIf="!requestId" class="col-1" style="padding-left: 10px; font-size: 16px">Ticket #</div>
    <div class="col-2" style="padding-left: 10px; font-size: 16px">Status</div>
 
   <div style="width: 100px"> -->
<!--     <div  *ngIf="!requestId" (click)="create()" class="btn btn-primary btn-sm float-end">-->
<!--       <i class="fas fa-sync" aria-hidden="true"></i> Create</div>-->
<!-- </div>
  </div>
 
  <div *ngFor="let task of this.data" class="row taskList">
    <div class="col-3 title">{{task.TaskName}}</div>
    <div class="col description" [innerHTML]="task.Instructions"></div>
    <div class="col-2" style="padding: 10px; border-left: solid lightgray;">
      <ng-select [items]="options?.agent"
                 bindLabel="name"
                 bindValue="id"
                 [(ngModel)]="task.AgentId"
                 (ngModelChange)="setAgent(task.Id, task.AgentId)"
                 [clearable]="false"
                 [disabled]="false">
      </ng-select>
 
    </div>
    <div  *ngIf="!requestId" class="col-1" style="padding: 10px; border-left: solid lightgray;">
      <span *ngIf="!task.RequestId">n/a</span>
      <a *ngIf="task.RequestId" [routerLink]="['/agent/request/', task.RequestId]" routerLinkActive="active">#{{task.RequestId}}</a>
    </div>
    <div class="col-2">
 
      <div *ngIf="task.SendCompletionEmail && !task.Completed" class="divLink">
        <i (click)="task.ShowCompletionEmailDialog = true" class="fal fa-hourglass sdesk-check"></i>
        <span style="margin-left: 5px" *ngIf="!task.Completed">Pending</span>
      </div>
 
      <div *ngIf="!task.Completed && !task.SendCompletionEmail" class="divLink">
        <i (click)="markComplete(task.Id)" class="fal fa-hourglass sdesk-check"></i>
        <span style="margin-left: 5px" *ngIf="!task.Completed">Pending</span>
      </div>
      <div *ngIf="task.Completed" class="divLink">
        <i (click)="markPending(task.Id)" class="fas fa-check sdesk-check completed divLink"></i>
        <span style="margin-left: 5px" *ngIf="!task.Completed">Complete</span>
      </div>
    </div>
    <div style="width: 100px">
      <i (click)="delete(task.Id)" class="fas fa-trash sdesk-check divLink float-end"></i>
    </div>
 
    <div class="row" style="background: white;margin: 10px;border-radius: 10px;width: 90%;padding:10px" *ngIf="task.SendCompletionEmail && task.ShowCompletionEmailDialog">
      <p style="
    background-color: #83a383;
    color: white;
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 10px;">In order to mark this task as complete. An e-mail must be sent to the Requester:</p>
      <quill-editor [(ngModel)]="task.CompletionEmailContents" [styles]="{height: '100px'}" placeholder=""></quill-editor>
 
      <div class="btn btn-primary btn-sm float-end" (click)="sendEmail(task.Id, task.CompletionEmailContents, task.RequestId)" style="width: 200px">Send & Mark as Complete</div>
    </div>
  </div>
 
</div> -->
 

  <div class="row" *ngIf="!requestId">
    <div class="col-md-6">
      <h4 class="p-10 mb-3"><strong>My Tasks</strong>&nbsp;&nbsp;<span>({{totalCount}})</span>
        <span class=""><i class="fas fa-sync divLink p-l-20" (click)="getData()" title="Reload"></i></span>
 
        </h4>
      </div>
      <div class="col-md-6">
 
<!-- start paginator -->
        <ng-container>
          <span class="pagination-mytickets">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalCount" [rowsPerPageOptions]="[50,100,500]"></p-paginator>
          </span>
        </ng-container>
<!-- end paginator -->
 
    </div>
</div>
 
<app-task-bulk-update [selectedTickets]="selectedTickets" [ticketsData]="data" (refreshData)="getData()"></app-task-bulk-update>
 
<!--new table grid added-->
<div class="col-md-12 sectionBox p-40">
 
  <div class="table-responsive m-t-20" style="width: 100%;">
    <table class="table align-middle tableStyle">
      <thead>
        <tr>
          <th class="" scope="col">
            <div class="form-check">
              <input [ngModel]="this.checkboxIsAllSelected()" (change)="this.checkboxToggleAll()"
                 class="form-check-input" type="checkbox" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault"></label>
            </div>
          </th>
          <th style="font-weight: normal;font-size: 14px;" *ngIf="isShowstatus">Task ID</th>
          <th style="font-weight: normal;font-size: 14px;" *ngIf="!isShowstatus" colspan="2">Task</th>
          <th style="font-weight: normal;font-size: 14px;" *ngIf="isShowstatus" >Task</th>
          <th style="font-weight: normal;font-size: 14px;">Instructions</th>
          <th style="font-weight: normal;font-size: 14px;">Group</th>
          <th style="font-weight: normal;font-size: 14px;">Assigned To</th>
          <th [ngStyle]="{'width': !isShowstatus ? '70px' : 'auto'}" style="font-weight: normal;font-size: 14px;">Ticket #</th>
          <th style="font-weight: normal;font-size: 14px;" *ngIf="isShowstatus">Status</th>
 
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr style="border-radius: 20px;border-top: 5px solid #ffffff;" class="taskList" *ngIf="isShowinline">
            <td colspan="2">
                <input *ngIf="newTask.isEditing" class="form-control" placeholder="Enter Task Name" [(ngModel)]="newTask.TaskName">
            </td>
            <td>
                <textarea *ngIf="newTask.isEditing" class="form-control"
                placeholder="Enter Instructions" [(ngModel)]="newTask.Instructions"></textarea>
            </td>
            <td style="width: 150px;">
              <ng-select [items]="options?.group" bindLabel="name" bindValue="id" [(ngModel)]="newTask.GroupId"
              (ngModelChange)="filterAgentsdata(newTask,$event)" [clearable]="true" [disabled]="false">
            </ng-select>
            </td>
            <td>
              <ng-select [items]="agentsEdit" bindLabel="name" bindValue="id" [(ngModel)]="newTask.AgentId"
                [clearable]="false" [disabled]="!newTask.GroupId">
              </ng-select>
            </td>
            <td>
              <span *ngIf="!newTask.RequestId">n/a</span>
              <a *ngIf="newTask.RequestId" [routerLink]="['/agent/request/', newTask.RequestId]"
                routerLinkActive="active">#{{newTask.LocalId}}</a>
            </td>
            <td></td>
            <td>
             <i (click)="savenewTaskChanges(newTask)" class="fas fa-check sdesk-check divLink float-end" style="background-color: rgb(23, 167, 23);"></i>
           
            </td>
            <td style="text-align: center;font-size: 18px;cursor: pointer;">
              <i (click)="deletefromArray(i)" class="fas fa-times"></i>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngFor="let task of data; let i = index">
          <tr style="border-radius: 20px;border-top: 5px solid #ffffff;" class="taskList">
            <td>
              <div class="form-check">
                <input [ngModel]="this.selectedTickets.isSelected(task.Id)"
                       (change)="this.selectedTickets.toggle(task.Id)" class="form-check-input" type="checkbox"
                       id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault"></label>
              </div>
            </td>
            <td>
              <span class="title">T{{task.Id}}</span>
            </td>
            <td style="min-width: 150px;">
              <span *ngIf="!task.isEdittable" class="title">{{task.TaskName}}</span>
              <input *ngIf="task.isEdittable" [(ngModel)]="task.TaskName" class="form-control" placeholder="Enter Task Name">
            </td>
            <td style="min-width: 150px;">

              <span
              [autoHide]="false"
              pTooltip="{{ task.InstructionsCopy }}"
              tooltipPosition="right"
              tooltipAppendTo="body"
              tooltipHideDelay="10000"
              tooltipStyleClass="cust-tooltip"
              *ngIf="!task.isEdittable" [innerHTML]="task.Instructions" style="word-break: break-word;">
              </span>
              
              <textarea *ngIf="task.isEdittable" [(ngModel)]="task.Instructions" class="form-control"

                placeholder="Enter Instructions"></textarea>
            </td>
            <td style="min-width: 150px;">
              <span *ngIf="!task.isEdittable" style="padding: unset;">{{task.GroupName}}</span>
              <ng-select *ngIf="task.isEdittable" [items]="options?.group" [(ngModel)]="task.GroupId" bindLabel="name" bindValue="id"
                (ngModelChange)="toggleAgents(task,i)" [clearable]="true" [disabled]="false">
              </ng-select>
            </td>      
            <td style="min-width: 125px;">
              <span *ngIf="!task.isEdittable">{{task.AgentName}}</span>
              <ng-select *ngIf="task.isEdittable" [items]="options.agent" [(ngModel)]="task.AgentId" bindValue="id" bindLabel="name"  
                (ngModelChange)="setAgent(task,task.Id, task.AgentId,'edit')" [clearable]="true" [disabled]="!task.GroupId">
              </ng-select>
            </td>
            <td>
              <span *ngIf="!task.RequestId">n/a</span>
              <a *ngIf="task.RequestId" [routerLink]="['/agent/request/', task.RequestId]"
                routerLinkActive="active">#{{task.LocalId}}</a>
            </td>
            <td *ngIf="!task.Id"></td>
            <td *ngIf="task.SendCompletionEmail && !task.Completed" style="min-width: 125px;">
 
              <i (click)="task.ShowCompletionEmailDialog = true" class="fal fa-hourglass sdesk-check"></i>
              <span style="margin-left: 5px" *ngIf="!task.Completed">Pending</span>
 
            </td>
            <td *ngIf="!task.Completed && !task.SendCompletionEmail && task.Id" class="divLink" style="min-width: 125px;">
              <i (click)="markComplete(task.Id)" class="fal fa-hourglass sdesk-check"></i>
              <span style="margin-left: 5px" *ngIf="!task.Completed">Pending</span>
            </td>
            <td *ngIf="task.Completed">
              <i (click)="markPending(task.Id)" class="fas fa-check sdesk-check completed divLink" style="background-color: rgb(23, 167, 23);"></i>
              <span style="margin-left: 5px" *ngIf="!task.Completed">Complete</span>
            </td>
           
            <td style="text-align: center;">
              <i *ngIf="!task.isEdittable" (click)="editTask(task.Id,task.RequestId,task.TypeId, true)" class="fas fa-edit sdesk-check divLink float-end" style="background-color: #506d88;"></i>
              <i *ngIf="task.isEdittable" (click)="saveTaskChanges(task.Id)" class="fas fa-check sdesk-check divLink float-end" style="background-color: rgb(23, 167, 23);"></i>
             </td>
            <td>
              <i (click)="delete(task.Id)" class="fas fa-trash sdesk-check divLink float-end"></i>
            </td>
            <!-- <td *ngIf="!task.Id" style="text-align: center;font-size: 18px;cursor: pointer;">
              <i (click)="deletefromArray(i)" class="fas fa-times"></i>
            </td> -->
 
          </tr>
          <tr *ngIf="task.SendCompletionEmail && task.ShowCompletionEmailDialog">
            <td colspan="8">
              <p style="
                background-color: #83a383;
                color: white;
                padding: 5px;
                border-radius: 10px;
                margin-bottom: 10px;">In order to mark this task as complete. An e-mail must be sent to the Requester:
              </p>
              <quill-editor [(ngModel)]="task.CompletionEmailContents" [styles]="{height: '100px'}"
                placeholder=""></quill-editor>
 
              <div class="btn btn-primary btn-sm float-end"
                (click)="sendEmail(task.Id, task.CompletionEmailContents, task.RequestId)" style="width: 200px">Send &
                Mark as Complete</div>
            </td>
          </tr>
        </ng-container>
 
      </tbody>
    </table>
  </div>
</div>