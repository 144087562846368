import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from "./agent/pages/dashboard/dashboard.component";
import {AgentComponent} from "./agent/agent.component";
import {SelfServiceComponent} from "./self-service/self-service.component";
import {LoginComponent} from "./login/login.component";
import {ParentComponent} from "./test/parent/parent.component";
import {FilterComponent} from "./agent/pages/filter/filter.component";
import {RequestComponent} from "./agent/pages/request/request.component";
import {SearchComponent} from "./agent/pages/search/search.component";
import {SelfServiceSearchComponent} from './self-service/self-service-search/search.component';
import {ReportingComponent} from "./agent/pages/reporting/reporting.component";
import {SelfServiceHomeComponent} from "./self-service/self-service-home/self-service-home.component";
import {SelfServiceMyTicketsComponent} from "./self-service/self-service-my-tickets/self-service-my-tickets.component";
import {SelfServiceTicketViewComponent} from "./self-service/self-service-ticket-view/self-service-ticket-view.component";
import {SelfServiceSolutionsArticleComponent} from "./self-service/self-service-solutions-article/self-service-solutions-article.component";
import {AdminComponent} from "./agent/pages/admin/admin.component";
import {ServiceDeskSettingsComponent} from "./agent/pages/admin/service-desk-settings/service-desk-settings.component";
import {AdminRequestTypesComponent} from "./agent/pages/admin/service-desk-settings/admin-request-types/admin-request-types.component";
import {AdminGlobalSettingsComponent} from "./agent/pages/admin/service-desk-settings/admin-global-settings/admin-global-settings.component";
import {AdminIcxSettingsComponent} from "./agent/pages/admin/service-desk-settings/admin-icx-settings/admin-icx-settings.component";
import {AdminSamlComponent} from "./agent/pages/admin/service-desk-settings/admin-saml/admin-saml.component";
import {AdminAzureAdComponent} from "./agent/pages/admin/service-desk-settings/admin-azure-ad-sync/admin-azure-ad-sync.component";
import {AdminUsersComponent} from "./agent/pages/admin/user-groups/admin-users/admin-users.component";
import {AdminUserCustomFieldsComponent} from "./agent/pages/admin/user-groups/admin-user-custom-fields/admin-user-custom-fields.component";
import {UserGroupsComponent} from "./agent/pages/admin/user-groups/user-groups.component";
import {AdminGroupsComponent} from "./agent/pages/admin/user-groups/admin-groups/admin-groups.component";
import {AdminCustomersComponent} from "./agent/pages/admin/customers/admin-customers/admin-customers.component";
import {ServicesVendorsComponent} from "./agent/pages/admin/services-vendors/services-vendors.component";
import {AdminServiceComponent} from "./agent/pages/admin/services-vendors/admin-services/admin-services.component";
import {AdminVendorsComponent} from "./agent/pages/admin/services-vendors/admin-vendors/admin-vendors.component";
import {EmailWebhooksComponent} from "./agent/pages/admin/email-webhooks/email-webhooks.component";
import {AdminEmailParseComponent} from "./agent/pages/admin/email-webhooks/admin-email-parse/admin-email-parse.component";
import {AdminWebhookComponent} from "./agent/pages/admin/email-webhooks/admin-webhooks/admin-webhooks.component";
import {AdminEmailTemplatesComponent} from "./agent/pages/admin/email-webhooks/admin-email-templates/admin-email-templates.component";
import {AdminAllowedSenderDomainsComponent} from "./agent/pages/admin/email-webhooks/admin-allowed-sender-domains/admin-allowed-sender-domains.component";
import {SlaComponent} from "./agent/pages/admin/sla/sla.component";
import {AdminSlaTemplatesComponent} from "./agent/pages/admin/sla/admin-sla-templates/admin-sla-templates.component";
import {AdminSlaHolidaysComponent} from "./agent/pages/admin/sla/admin-sla-holidays/admin-sla-holidays.component";
import {AdminLinkConfigurationComponent} from "./agent/pages/admin/service-desk-settings/admin-link-configuration/admin-link-configuration.component";
import {AdminCannedResponsesComponent} from "./agent/pages/admin/admin-canned-responses/admin-canned-responses.component";
import {AdminScheduledRequestsComponent} from "./agent/pages/admin/admin-scheduled-requests/admin-scheduled-requests.component";
import {TypeSettingsComponent} from "./agent/pages/admin/type-settings/type-settings.component";
import {AdminAutomationComponent} from "./agent/pages/admin/admin-automation/admin-automation.component";
import {AdminCustomerPortalsComponent} from "./agent/pages/admin/admin-customer-portal/admin-customer-portal.component";
import {TermsOfServiceComponent} from "./agent/pages/terms-of-service/terms-of-service.component";
import {MassCommunicationComponent} from "./agent/pages/mass-communication/mass-communication.component";
import {RootComponent} from './agent/pages/root/root.component';
import {AssetRegisterComponent} from './agent/pages/assets/asset-register/asset-register.component';
import {AdminTaskListsComponent} from './agent/pages/admin/admin-task-lists/admin-task-lists.component';
import {TasksComponent} from './agent/pages/tasks/tasks.component';
import {AllTasksComponent} from './agent/pages/tasks/all-tasks/all-tasks.component';
import {ChatComponent} from './agent/pages/chat/chat.component';
import {AdminChatConfigComponent} from './agent/pages/admin/admin-chat-config/admin-chat-config.component';
import {AuditLogComponent} from './agent/pages/admin/audit-log/audit-log.component';

const routes: Routes = [
  {
    path: 'agent',
    component: AgentComponent,
    children: [
      {path: 'terms-of-service', component: TermsOfServiceComponent},
      {path: '', pathMatch: 'full', redirectTo: 'dashboard/stats'},
      {path: 'dashboard/:tab', component: DashboardComponent},
      {path: 'dashboard', redirectTo: 'dashboard/stats'},
      {path: 'tasks', component: TasksComponent},
      {path: 'all-tasks', component: AllTasksComponent},
      {path: 'assets', component: AssetRegisterComponent},
      {path: 'mass-communication', component: MassCommunicationComponent},
      {path: 'view/:ViewName/type/:RequestTypeId', component: FilterComponent},
      {path: 'view/:ViewName', component: FilterComponent},
      {path: 'chat/:chatConfigId/:viewName/:RequestId', component: ChatComponent},
      {path: 'chat/:chatConfigId/:viewName', component: ChatComponent},
      {path: 'filter/:FilterId', component: FilterComponent},
      {path: 'request/:RequestId', component: RequestComponent},
      {path: 'search', component: SearchComponent},
      {path: 'reporting/:tab', component: ReportingComponent},
      {path: 'reporting', redirectTo: 'reporting/stats'},
      {path: 'admin/chat-config', component: AdminChatConfigComponent},
      {path: 'admin/cannedresponses', component: AdminCannedResponsesComponent},
      {path: 'admin/scheduled', component: AdminScheduledRequestsComponent},
      {path: 'admin/automation', component: AdminAutomationComponent},
      {path: 'admin/task-lists', component: AdminTaskListsComponent},
      {path: 'admin/customerportals', component: AdminCustomerPortalsComponent},
      {path: 'admin/audit-log', component: AuditLogComponent},
      {
        path: 'admin/global', component: ServiceDeskSettingsComponent,
        children: [
          {path: 'settings', component: AdminGlobalSettingsComponent},
          {path: 'types', component: AdminRequestTypesComponent},
          {path: 'sso', component: AdminSamlComponent},
          {path: 'azuread', component: AdminAzureAdComponent},
          {path: 'links', component: AdminLinkConfigurationComponent},
          {path: 'icx', component: AdminIcxSettingsComponent},
        ]
      },
      {
        path: 'admin/users-groups', component: UserGroupsComponent,
        children: [
          {path: 'users', component: AdminUsersComponent},
          {path: 'groups', component: AdminGroupsComponent},
          {path:  'custom-fields',component: AdminUserCustomFieldsComponent}
        ]
      },
      {path: 'admin/customers', component: AdminCustomersComponent},
      {
        path: 'admin/services-vendors', component: ServicesVendorsComponent,
        children: [
          {path: 'services', component: AdminServiceComponent},
          {path: 'vendors', component: AdminVendorsComponent},
        ]
      },
      {
        path: 'admin/email-webhooks', component: EmailWebhooksComponent,
        children: [
          {path: 'email', component: AdminEmailParseComponent},
          {path: 'webhooks', component: AdminWebhookComponent},
          {path: 'templates', component: AdminEmailTemplatesComponent},
          {path: 'outbound', component: AdminAllowedSenderDomainsComponent},

        ]
      },
      {
        path: 'admin/sla', component: SlaComponent,
        children: [
          {path: 'templates', component: AdminSlaTemplatesComponent},
          {path: 'holidays', component: AdminSlaHolidaysComponent}

        ]
      },
      {
        path: 'admin/type/:TypeId/:SelectedTab', component: TypeSettingsComponent,
      },
      {
        path: 'root', component: RootComponent
      }
    ]
  },
  {
    path: '',
    component: SelfServiceComponent,
    children: [
      {path: '', component: SelfServiceHomeComponent},
      {path: 'solutions/:ArticleUrl', component: SelfServiceSolutionsArticleComponent},
      {path: 'mytickets', component: SelfServiceMyTicketsComponent},
      {path: 'ticket/:RequestId', component: SelfServiceTicketViewComponent},
      {path: 'search', component: SelfServiceSearchComponent}
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'test',
    component: ParentComponent
  },
  {path: '**', redirectTo: '/'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
