<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="row">
  <div class="col-lg-2 sectionBox p-20" style="height: 100%; margin: 10px">
    <app-loading-spinner *ngIf="isLoadingOptions"></app-loading-spinner>

    <div *ngIf="!isLoadingOptions">

      <div class="filter-actions">

  <div *ngIf="hasCacheFilter() && !applyFilterVisible()"
    (click)="confirmeleteCacheFilter()"
    class="button reset-filter">
    Reset
  </div>

  <div
    *ngIf="filterAgentIdsChanged || filterDateChanged || filterTypeIdsChanged || filterGroupIdsChanged || filterStatusIdsChanged || filterCustomerIdsChanged"
    (click)="getData(true)"
    class="button apply-filters">
    Apply Filters
  </div>

  <div

    (click)="saveReportingFilter()"
    class="button save-filter">
    Save Filter
  </div>
</div>

<div class="date-section">
  <h4>Date</h4>
  <ng-select
    [items]="predefinedDates"
    bindLabel="name"
    bindValue="id"
    [(ngModel)]="selectedReportPeriod"
    (ngModelChange)="switchDates(); filterDateChanged = true"
    [clearable]="false"
    [disabled]="isLoadingStats || isLoadingTicketList">
  </ng-select>

  <div *ngIf="selectedReportPeriod == 'Custom'" class="date-picker">
    <span>
      <input (dateChange)="filterDateChanged = true" style="display:none" matInput [matDatepicker]="dateFromPicker" [(ngModel)]="dateFrom">
      <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
      {{dateFrom | date}}
      <mat-datepicker #dateFromPicker [touchUi]="true"></mat-datepicker>
    </span>

    <span>
      <input (dateChange)="filterDateChanged = true" style="display:none" class="form-control" matInput [matDatepicker]="dateToPicker" [(ngModel)]="dateTo">
      <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
      {{dateTo | date}}
      <mat-datepicker #dateToPicker [touchUi]="true"></mat-datepicker>
    </span>
  </div>
</div>

      <hr class="m-t-20 m-b-20">

      <div class="request-types-container" (click)="toggleRequestTypesDropdown()">
        <h4 class="request-types-title ">Request Types</h4>
        <i [ngClass]="isRequestTypesDropdownVisible ? 'fa-chevron-up' : 'fa-chevron-down'" class="fas float-end"></i>
      </div>

      <div *ngIf="isRequestTypesDropdownVisible">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="selectAll" [checked]="isAllRequestTypesOptionsSelected()"
            (change)="toggleSelectAll($event.target.checked, 'requestTypes')">
          <label class="form-check-label" for="selectAll">Select All</label>
        </div>
        <hr class="m-t-5 m-b-5">
        <div *ngFor="let x of this.options.type" class="form-check">

          <input [ngModel]="this.filterTypeIds.isSelected(x.id)" [disabled]="isLoadingStats || isLoadingTicketList"
            (change)="this.filterTypeIds.toggle(x.id);" [ngModelOptions]="{standalone: true}" name="{{x.id}}_{{x.name}}"
            class="form-check-input" type="checkbox" id="{{x.id}}_{{x.name}}">

          <label class="form-check-label dropdown-toggle" (click)="toggleDropdown(x.id)">{{x.name}}</label>

          <!-- START STATUS TYPES -->
          <div class="custome-form-control" *ngIf="isDropdownVisible(x.id)">

            <ng-container *ngIf="x.id !== null; else allStatus">
              <ng-container *ngFor="let status of getStatusesByTypeId(x.id)">
                <div class="d-flex flex-row custom-status">
                  <input type="checkbox" class="form-check-input status-checkbox custom-status-checkbox"
                    name="{{status.id}}_{{status.name}}" [checked]="status.selectedByDefault"
                    [disabled]="!filterTypeIds.isSelected(x.id)" (change)="toggleStatusSelection(status, x.id)">
                  <label class="">{{status.name}}</label>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <!-- END STATUS TYPES -->

        </div>

      </div>

      <hr class="m-t-20 m-b-20">

      <div class="request-types-container" (click)="toggleGroupsDropdown()">
        <h4 class="request-types-title ">Groups</h4>
        <i [ngClass]="isGroupsDropdownVisible ? 'fa-chevron-up' : 'fa-chevron-down'" class="fas float-end"></i>
      </div>
      <div *ngIf="isGroupsDropdownVisible">
        <input type="text" [(ngModel)]="groupSearch" class="form-control" placeholder="Group search.." (input)="filterGroups()">
        <div  class="form-check">
          <input class="form-check-input" type="checkbox" id="selectAllGroups" [checked]="isAllGroupsOptionsSelected()"
            (change)="toggleSelectAll($event.target.checked, 'groups')">
          <label class="form-check-label" for="selectAllGroups">Select All</label>
        </div>
        <div *ngFor="let x of filteredGroups" class="form-check">
          <input [ngModel]="this.filterGroupIds.isSelected(x.id)" [disabled]="isLoadingStats || isLoadingTicketList"
            (change)="this.filterGroupIds.toggle(x.id); " [ngModelOptions]="{standalone: true}"
            name="{{x.id}}_{{x.name}}" class="form-check-input" type="checkbox" id="{{x.id}}_{{x.name}}">
          <label class="form-check-label" for="{{x.id}}_{{x.name}}">{{x.name}} <span *ngIf="x.enabled === 0">(Inactive)</span></label>
        </div>
      </div>
      <hr class="m-t-20 m-b-20">
      <div class="request-types-container" (click)="toggleAgentsDropdown()">
        <h4 class="request-types-title ">Agents</h4>
        <i [ngClass]="isAgentsDropdownVisible ? 'fa-chevron-up' : 'fa-chevron-down'" class="fas float-end"></i>
      </div>
      <div *ngIf="isAgentsDropdownVisible">
        <input type="text" [(ngModel)]="agentSearch" class="form-control" placeholder="Search agents.." (input)="filterAgents()">
        <div  class="form-check">
          <input class="form-check-input" type="checkbox" id="selectAllAgents" [checked]="isAllAgentsOptionsSelected()"
            (change)="toggleSelectAll($event.target.checked, 'agents')">
          <label class="form-check-label" for="selectAllAgents">Select All</label>
        </div>
        <div *ngFor="let x of filteredAgents" class="form-check">
          <input [ngModel]="this.filterAgentIds.isSelected(x.id)" [disabled]="isLoadingStats || isLoadingTicketList"
            (change)="this.filterAgentIds.toggle(x.id); " [ngModelOptions]="{standalone: true}"
            name="{{x.id}}_{{x.name}}" class="form-check-input" type="checkbox" id="{{x.id}}_{{x.name}}">
          <label class="form-check-label" for="{{x.id}}_{{x.name}}">{{x.name}}</label>
        </div>
      </div>

      <hr class="m-t-20 m-b-20">

      <div class="request-types-container" (click)="toggleCustomerDropDown()">
        <h4 class="request-types-title ">Customers</h4>
        <i [ngClass]="isCustomersDropdownVisible ? 'fa-chevron-up' : 'fa-chevron-down'" class="fas float-end"></i>
      </div>
      <div *ngIf="isCustomersDropdownVisible">
        <input type="text" [(ngModel)]="customerSearch" class="form-control" placeholder="Search customers..." (input)="filterCustomers()">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="selectAllCustomers" [checked]="isAllCustomerOptionsSelected()"
                (change)="toggleSelectAll($event.target.checked, 'customer')">
            <label class="form-check-label" for="selectAllCustomers">Select All</label>
        </div>
        <div *ngFor="let x of filteredCustomers" class="form-check">
            <input [ngModel]="filterCustomerIds.isSelected(x.id)" [disabled]="isLoadingStats || isLoadingTicketList"
                (change)="filterCustomerIds.toggle(x.id);" [ngModelOptions]="{standalone: true}"
                name="{{x.id}}_{{x.name}}" class="form-check-input" type="checkbox" id="{{x.id}}_{{x.name}}">
            <label class="form-check-label" for="{{x.id}}_{{x.name}}">{{x.name}}</label>
        </div>
    </div>

      <hr class="m-t-20 m-b-20">
      <div class="request-types-container" (click)="toggleFilterDropDown()">
        <h4 class="request-types-title ">Filters</h4>
        <i [ngClass]="isFilterDropdownVisible ? 'fa-chevron-up' : 'fa-chevron-down'" class="fas float-end"></i>
      </div>
      <div *ngIf="isFilterDropdownVisible"  class="filter-dropdown">
        <div *ngFor = "let filter of allReportFilters" class="filter-item">
          <div (click)=getReportFilterDetails(filter.Id) class="filter-name"> {{filter["Name"]}}</div>
          <i class="far fa-trash-alt float-end divLink filter-icon" (click)="deleteReportingFilter(filter.Id)"></i>
        </div>

      </div>




    </div>

  </div>
  <div class="col-lg">

    <app-loading-spinner
      *ngIf="isLoadingStats || isLoadingTicketList || isLoadingTimeElapsedReport"></app-loading-spinner>

    <div *ngIf="!isLoadingStats && !isLoadingTicketList && !isLoadingTimeElapsedReport">
      <div id="dashboardTabs">
        <div class="row">
          <div class="col">
            <div routerLink="/agent/reporting/stats" routerLinkActive="active"
              class="dashboardTile divLink text-center">
              <div class="icon p-l-5"><i class="fas fa-chart-pie fa-3x graph"></i></div>
            </div>
          </div>

          <div class="col">
            <div routerLink="/agent/reporting/tickets" routerLinkActive="active"
              class="dashboardTile divLink text-center">
              <h5 class="value">{{this.totalNumberOfTickets}}</h5>
              <h6 class="label">Tickets</h6>
            </div>
          </div>

          <div class="col">
            <div routerLink="/agent/reporting/agent" routerLinkActive="active" class="dashboardTile divLink">
              <h5 class="reportingTileTitle">Agent</h5>
              <h6 class="label">Performance</h6>
            </div>
          </div>

          <div class="col">
            <div routerLink="/agent/reporting/sla" routerLinkActive="active" class="dashboardTile divLink">
              <h5 class="reportingTileTitle">SLA</h5>
              <h6 class="label">Stats</h6>
            </div>
          </div>

          <div class="col" *ngIf="!hidetabTop">
            <div routerLink="/agent/reporting/top" routerLinkActive="active" class="dashboardTile divLink">
              <h5 class="reportingTileTitle">Top</h5>
              <h6 class="label">Stats</h6>
            </div>
          </div>

          <div class="col">
            <div routerLink="/agent/reporting/survey" routerLinkActive="active" class="dashboardTile divLink">
              <h5 class="reportingTileTitle">Survey</h5>
              <h6 class="label">Results</h6>
            </div>
          </div>

          <div class="col ">
            <div routerLink="/agent/reporting/elapsed-time" routerLinkActive="active"
              class="dashboardTile divLink text-center">
              <div class="icon p-l-5"><i class="fas fa-stopwatch fa-3x graph"></i></div>
            </div>
          </div>
          <div class="col">
            <div class="dropdown">
              <div class="dropdown-toggle dashboardTile divLink text-center" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                <div class="icon"><i class="fas fa-file-download fa-3x graph"></i></div>
              </div>
              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <li class="dropdown_list">
                  <div (click)="downloadReports(true)" class="dropdown-item">Export with Task</div>
                </li>
                <li class="dropdown_list">
                  <div (click)="downloadReports(false)" class="dropdown-item">Export without Task</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="dashboardContent">


        <div *ngIf="selectedTab == 'stats'">

          <div class="row">
            <div class="col-6">
              <div class="sectionBox">
                <h5 class="label">Request Type</h5>
                <app-highchart-pie [inputData]="this.reportingData.RequestType"
                  [useStandardColours]="true"></app-highchart-pie>
              </div>
            </div>
            <div class="col-6">
              <div class="sectionBox">
                <h5 class="label">Priority</h5>
                <app-highchart-pie [inputData]="this.reportingData.Priority"></app-highchart-pie>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="sectionBox">
                <h5 class="label">Group</h5>
                <app-highchart-column [inputData]="this.reportingData.Group"
                  [inputColor]="'indianred'"></app-highchart-column>
              </div>
            </div>
            <div class="col-6">
              <div class="sectionBox">
                <h5 class="label">Agent</h5>
                <app-highchart-column [inputData]="this.reportingData.Agent"
                  [inputColor]="'#e29578'"></app-highchart-column>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab == 'agent'" class="sectionBox p-0">

          <table class="table align-middle tableStyle">
            <thead>
              <tr>
                <th class=""></th>
                <th class="">Tickets Solved</th>
                <th class="">Average Response Time</th>
                <th class="col-7">SLA Indicator</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let agent of agentPerfData">
                <th scope="row">{{ agent.name ? agent.name : 'Unassigned' }}</th>
                <td>{{agent.agentsTickets.length}}</td>
                <td>{{agent.averageResponseTime}}</td>
                <td>
                  <div [ngStyle]="" style="width:{{agent.agentsTickets.length / agentPerfDataMaxValue * 100}}%"
                    class="reportingAgentPerformanceSlaContainer">
                    <!-- Within SLA -->
                    <div [matTooltip]="agent.tooltipWithinSla" [matTooltipPosition]="'above'"
                      [matTooltipClass]="'sdesk-tooltip'" style="width: {{agent.percentWithinSla}}%"
                      class="reportingAgentPerformanceWithinSla"></div>
                    <!-- Breached SLA -->
                    <div [matTooltip]="agent.tooltipBreachedSla" [matTooltipPosition]="'above'"
                      [matTooltipClass]="'sdesk-tooltip'" style="width: {{agent.percentBreached}}%"
                      class="reportingAgentPerformanceSlaBreached"></div>
                      <!-- No SLA -->
                    <div [matTooltip]="agent.tooltipNoSla" [matTooltipPosition]="'above'"
                    [matTooltipClass]="'sdesk-tooltip'" style="width: {{agent.percentNoSla}}%" 
                    class="reportingAgentPerformanceRemaining">
                  </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="selectedTab == 'sla'">
          <div class="row">
            <div class="col">
              <div class="sectionBox">
                <div class="row">
                  <div class="col">
                    <div class="sectionBox">
                      <h5 class="label">Response <span>Average: {{getAverageResponseTime()}}</span></h5>
                      <app-highchart-pie [inputData]="this.reportingData.SlaResponse"></app-highchart-pie>
                    </div>
                  </div>
                  <div class="col">
                    <div class="sectionBox sdesk-left-border">
                      <h5 class="label">Resolution <span>Average: {{getAverageResolutionTime()}}</span></h5>
                      <app-highchart-pie [inputData]="this.reportingData.SlaResolution"></app-highchart-pie>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

        <div *ngIf="selectedTab == 'top'" class="sectionBox">


          <div *ngFor="let customField of this.reportingData.CustomFieldsData" class="row">

            <div class="col">
              <div class="sectionBox">
                <h4 class="panel-title">{{customField.Name}} Level 1</h4>
                <table class="table">
                  <tbody>
                    <tr *ngFor="let stat of customField.Level1 | slice:0:10; let i=index">
                      <th scope="row">{{stat.Name}}</th>
                      <td>{{stat.Count}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col ">
              <div class="sectionBox sdesk-left-border">
                <h4 class="panel-title">{{customField.Name}} Level 2</h4>
                <table class="table">
                  <tbody>
                    <tr *ngFor="let stat of customField.Level2 | slice:0:10; let i=index">
                      <th scope="row">{{stat.Name}}</th>
                      <td>{{stat.Count}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col ">
              <div class="sectionBox sdesk-left-border">
                <h4 class="panel-title">{{customField.Name}} Level 3</h4>
                <table class="table">
                  <tbody>
                    <tr *ngFor="let stat of customField.Level3 | slice:0:10; let i=index">
                      <th scope="row">{{stat.Name}}</th>
                      <td>{{stat.Count}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>

        <div *ngIf="selectedTab == 'tickets'">
          <div class="sectionBox p-0">
            <div class="table-responsive">
                <p-table
                #dt
                [value]="this.ticketListWithPagination" class="table align-middle tableStyle">

                <ng-template pTemplate="header">
                  <tr style="padding:10px">
                    <th (click)="sortColumn('Id')" pSortableColumn="Id" style="padding: 10px;">Id
                      <i *ngIf="orderTableBy === 'Id'" [class.fa-arrow-up]="orderTableByAsc"
                      [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
                    </th>

                    <th (click)="sortColumn('RequestType')" pSortableColumn="RequestType" class="col-sm">Type
                      <i *ngIf="orderTableBy === 'RequestType'" [class.fa-arrow-up]="orderTableByAsc"
                      [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>

                    </th>

                    <th (click)="sortColumn('RequesterName')" pSortableColumn="RequesterName" class="col-sm-1">Requester
                      <i *ngIf="orderTableBy === 'RequesterName'" [class.fa-arrow-up]="orderTableByAsc"
                      [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>

                    </th>

                    <th (click)="sortColumn('Subject')"  pSortableColumn="Subject" class="col-sm-3">Subject
                      <i *ngIf="orderTableBy === 'Subject'" [class.fa-arrow-up]="orderTableByAsc"
                      [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>

                    </th>

                    <th (click)="sortColumn('Priority')" pSortableColumn="Priority" class="col-sm">Priority
                      <i *ngIf="orderTableBy === 'Priority'" [class.fa-arrow-up]="orderTableByAsc"
                      [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
                    </th>

                    <th (click)="sortColumn('Agent')" pSortableColumn="Agent" class="col-sm">Agent
                      <i *ngIf="orderTableBy === 'Agent'" [class.fa-arrow-up]="orderTableByAsc"
                      [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
                    </th>

                    <th (click)="sortColumn('Status')" pSortableColumn="Status" class="col-sm">Status
                      <i *ngIf="orderTableBy === 'Status'" [class.fa-arrow-up]="orderTableByAsc"
                      [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
                    </th>

                    <th (click)="sortColumn('SlaResponseOverdue')" pSortableColumn="SlaResponseOverdue" class="col-sm">Response
                      <i *ngIf="orderTableBy === 'SlaResponseOverdue'" [class.fa-arrow-up]="orderTableByAsc"
                      [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>

                    </th>

                    <th (click)="sortColumn('SlaResolutionOverdue')" pSortableColumn="SlaResolutionOverdue" class="col-sm">Resolution
                      <i *ngIf="orderTableBy === 'SlaResolutionOverdue'" [class.fa-arrow-up]="orderTableByAsc"
                      [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
                    </th>

                    <th (click)="sortColumn('TotalTimeTrackingSeconds')" pSortableColumn="TotalTimeTrackingSeconds" class="col-sm">Elapsed Time
                      <i *ngIf="orderTableBy === 'TotalTimeTrackingSeconds'" [class.fa-arrow-up]="orderTableByAsc"
                      [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
                    </th>

                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ticket>
                  <tr style="padding:10px" class="divLink" [routerLink]="['/agent/request/', ticket.GlobalTicketRef]">
                    <td>{{ticket.Id}}</td>
                    <td>{{ticket.RequestType}}</td>
                    <td>{{ticket.RequesterName}}</td>
                    <td [innerHTML]="ticket.Subject"></td>
                    <td>{{ticket.Priority}}</td>
                    <td>{{ticket.Agent}}</td>
                    <td>{{ticket.Status}}</td>
                    <td>
                      <span *ngIf="!ticket.ResponseDueDate" class="label m-0 SDeskSlaIndicator-Paused">No SLA</span>
                      <span *ngIf="ticket.ResponseDueDate && !ticket.SlaResponseOverdue"
                        class="label m-0 SDeskSlaIndicator-Green">Within SLA</span>
                      <span *ngIf="ticket.ResponseDueDate && ticket.SlaResponseOverdue"
                        class="label m-0 SDeskSlaIndicator-Red">SLA Breached</span>
                    </td>
                    <td>
                      <span *ngIf="!ticket.ResolutionDueDate" class="label m-0 SDeskSlaIndicator-Paused">No SLA</span>
                      <span *ngIf="ticket.ResolutionDueDate && !ticket.SlaResolutionOverdue"
                        class="label m-0 SDeskSlaIndicator-Green">Within SLA</span>
                      <span *ngIf="ticket.ResolutionDueDate && ticket.SlaResolutionOverdue"
                        class="label m-0 SDeskSlaIndicator-Red">SLA Breached</span>
                    </td>
                    <td>{{secondsToHms(ticket.TotalTimeTrackingSeconds)}}</td>

                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <!--Start Paginator  -->
          <ng-container>
            <span class="pagination-mytickets">
                <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalNumberOfTickets" [rowsPerPageOptions]="[50,100,500]"></p-paginator>
            </span>
          </ng-container>
        <!--End Paginator  -->
        </div>
        <div *ngIf="selectedTab == 'elapsed-time'">


          <div class="sectionBox p-0">
            <!--            // manual/tickets/group-customer -->
            <div class="p-20">
              <span class=" divLink" [style.font-weight]="timeElapsedSubCategory == 'manual' ? 'bold' : ''"
                (click)="timeElapsedSubCategory = 'manual'"> Manual Entries |</span>
              <span class=" divLink" [style.font-weight]="timeElapsedSubCategory == 'ticket' ? 'bold' : ''"
                (click)="timeElapsedSubCategory = 'ticket'"> Ticket Entries |</span>
              <span class=" divLink" [style.font-weight]="timeElapsedSubCategory == 'group-customer' ? 'bold' : ''"
                (click)="timeElapsedSubCategory = 'group-customer'"> Group by Customer</span>
            </div>

            <div *ngIf="timeElapsedSubCategory == 'manual'" class="table-responsive">
              <p-table [value]="this.timeElapsedData" sortField="Id" [sortOrder]="-1"
                class="table align-middle tableStyle">
                <ng-template pTemplate="header">
                  <tr style="padding:10px">
                    <th pSortableColumn="AgentName" class="col-sm-1">AgentName
                      <p-sortIcon [field]="'AgentName'"></p-sortIcon>
                    </th>
                    <th pSortableColumn="TotalSeconds" class="col-sm-1">Elapsed
                      <p-sortIcon field="TotalSeconds"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Comment" class="col-sm-6">Subject/Comment
                      <p-sortIcon field="Comment"></p-sortIcon>
                    </th>


                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ticket>
                  <tr style="padding:10px">
                    <td>{{ticket.AgentName}}</td>
                    <td>{{secondsToHms(ticket.TotalTimeTrackingSeconds)}}</td>
                    <td>{{ticket.Subject}}</td>

                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div *ngIf="timeElapsedSubCategory == 'ticket'" class="table-responsive">
              <p-table [value]="this.ticketList" sortField="Id" [sortOrder]="-1" class="table align-middle tableStyle">
                <ng-template pTemplate="header">
                  <tr style="padding:10px">
                    <th pSortableColumn="Id" style="padding: 10px;">Id
                      <p-sortIcon [field]="'Id'"></p-sortIcon>
                    </th>
                    <th pSortableColumn="RequestType" class="col-sm">Type
                      <p-sortIcon field="RequestType"></p-sortIcon>
                    </th>
                    <th pSortableColumn="RequesterName" class="col-sm-1">Requester
                      <p-sortIcon field="RequesterName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Subject" class="col-sm-3">Subject
                      <p-sortIcon field="Subject"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Agent" class="col-sm">Agent
                      <p-sortIcon field="Agent"></p-sortIcon>
                    </th>
                    <th pSortableColumn="TotalTimeTrackingSeconds" class="col-sm">Elapsed Time
                      <p-sortIcon field="TotalTimeTrackingSeconds"></p-sortIcon>
                    </th>

                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ticket>
                  <tr style="padding:10px" class="divLink" [routerLink]="['/agent/request/', ticket.GlobalTicketRef]">
                    <td>{{ticket.Id}}</td>
                    <td>{{ticket.RequestType}}</td>
                    <td>{{ticket.RequesterName}}</td>
                    <td>{{ticket.Subject}}</td>
                    <td>{{ticket.Agent}}</td>
                    <td>{{secondsToHms(ticket.TotalTimeTrackingSeconds)}}</td>

                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div *ngIf="timeElapsedSubCategory == 'group-customer'" class="table-responsive">
              <p-table [value]="this.timeElapsedCustomerData" sortField="Id" [sortOrder]="-1"
                class="table align-middle tableStyle">
                <ng-template pTemplate="header">
                  <tr style="padding:10px">

                    <th pSortableColumn="CustomerName" class="col-sm">Customer
                      <p-sortIcon field="CustomerName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="TotalTimeTrackingSeconds" class="col-sm">Elapsed Time
                      <p-sortIcon field="TotalTimeTrackingSeconds"></p-sortIcon>
                    </th>

                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ticket>
                  <tr style="padding:10px">
                    <td>{{ticket.CustomerName}}</td>

                    <td>{{secondsToHms(ticket.TotalTimeTrackingSeconds)}}</td>

                  </tr>
                </ng-template>
              </p-table>
            </div>


          </div>
        </div>

        <div *ngIf="selectedTab == 'survey'">
          <div class="sectionBox p-0">

            <div *ngIf="surveyAllCount == 0">
              <h4 class="p-20"> Not enough data </h4>
            </div>
            <div *ngIf="surveyAllCount > 0">

              <div class="row">

                  <!-- start response rate -->
                  <div class="col statisticLeftBorder2 divLink" [class.active]="surveyFilterTicketsRating == null"
                (click)="surveyFilterTicketsRating = null;getSurveyVarity(surveyFilterTicketsRating)"
                >
                  <div class="row justify-content-center align-items-center">
                    <div class="col-6 text-left">
                      Response Rate
                    </div>
                    <div class="col-1">
                    </div>
                    <div class="col-5 text-left">
                      <span style="font-size: 1.25rem;">{{surveyAllCount === 0 ? 0 : ((surveyTotalResponses() / surveyAllCount) * 100).toFixed(2)}}%</span>
                    </div>
                  </div>

                  <div class="row justify-content-center align-items-center">
                    <div class="col-6 text-left">
                      <span style="font-size: 1rem;">Total Sent</span>
                    </div>
                    <div class="col-1">
                    </div>
                    <div class="col-5 text-left">
                      <span style="font-size: 1rem;">{{surveyAllCount}}</span>
                    </div>
                  </div>

                  <div class="row justify-content-center align-items-center">
                    <div class="col-6 text-left">
                      <span style="font-size: 1rem;">Total  Responses</span>
                    </div>
                    <div class="col-1">
                    </div>
                    <div class="col-5 text-left">
                      <span style="font-size: 1rem;">{{surveyTotalResponses()}}</span>
                    </div>
                  </div>

                </div>
                <!-- end response rate -->

                <!-- <div class="col statisticLeftBorder">
                  
                </div> -->

                <!-- <div class="col statisticLeftBorder divLink" [class.active]="surveyFilterTicketsRating == null"
                  (click)="surveyFilterTicketsRating = null">
                  
                </div> -->

                <!-- start good -->
                <div class="col statisticLeftBorder green divLink" [class.active]="surveyFilterTicketsRating == 1"
                  (click)="surveyFilterTicketsRating = 1 ; getSurveyVarity(surveyFilterTicketsRating)">
                  <div class="row mt-1p9 justify-content-center align-items-center">
                    <div class="col-12 text-center">
                      Good
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span style="font-size: 1.25rem;">
                        {{surveyTotalResponses() === 0 ? 0 :((surveyGoodResponses() / surveyTotalResponses()) * 100).toFixed(2)}}%
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end good -->

                <!-- start average -->
                <div class="col statisticLeftBorder amber divLink" [class.active]="surveyFilterTicketsRating == 2"
                  (click)="surveyFilterTicketsRating = 2 ; getSurveyVarity(surveyFilterTicketsRating)">
                  <div class="row mt-1p9 justify-content-center align-items-center">
                    <div class="col-12 text-center">
                      Average
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span style="font-size: 1.25rem;">
                        {{surveyTotalResponses() === 0 ? 0 :((surveyAverageResponses() / surveyTotalResponses()) * 100).toFixed(2)}}%
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end average -->

                <!-- start bad -->
                <div class="col statisticLeftBorder red divLink" [class.active]="surveyFilterTicketsRating == 3"
                  (click)="surveyFilterTicketsRating = 3; getSurveyVarity(surveyFilterTicketsRating)">
                  <div class="row mt-1p9 justify-content-center align-items-center">
                    <div class="col-12 text-center">
                      Bad
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span style="font-size: 1.25rem;">
                        {{surveyTotalResponses() === 0 ? 0 :((surveyBadResponses() / surveyTotalResponses()) * 100).toFixed(2)}}%
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end bad -->

              </div>

              <div class="table-responsive">
                <p-table [value]="this.surveyTicketList()" sortField="RequestId" [sortOrder]="-1"
                  class="table align-middle tableStyle">
                  <ng-template pTemplate="header">
                    <tr style="padding:10px">
                      <th pSortableColumn="RequestId" style="padding: 10px;">Completion Date
                        <p-sortIcon [field]="'RequestId'"></p-sortIcon>
                      </th>
                      <th pSortableColumn="RequestId" style="padding: 10px;">Id
                        <p-sortIcon [field]="'RequestId'"></p-sortIcon>
                      </th>
                      <th pSortableColumn="RequestType" class="col-sm">Type
                        <p-sortIcon field="RequestType"></p-sortIcon>
                      </th>
                      <th pSortableColumn="RequesterName" class="col-sm-1">Requester
                        <p-sortIcon field="RequesterName"></p-sortIcon>
                      </th>
                      <th pSortableColumn="Subject" class="col-sm-3">Subject
                        <p-sortIcon field="Subject"></p-sortIcon>
                      </th>
                      <th pSortableColumn="Priority" class="col-sm">Priority
                        <p-sortIcon field="Priority"></p-sortIcon>
                      </th>
                      <th pSortableColumn="Agent" class="col-sm">Agent
                        <p-sortIcon field="Agent"></p-sortIcon>
                      </th>
                      <th pSortableColumn="SurveyResult" class="col-sm">Survey Response
                        <p-sortIcon field="SurveyResult"></p-sortIcon>
                      </th>
                      <th pSortableColumn="SurveyComment" class="col-sm-2">Comment
                        <p-sortIcon field="SurveyComment"></p-sortIcon>
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-ticket>
                    <tr style="padding:10px" class="divLink" [routerLink]="['/agent/request/', ticket.RequestId]">
                      <td [routerLink]="['/agent/request/', ticket.RequestId]">
                        <app-friendly-time                           
                          [utcTimeData]="ticket.SurveyDateTimeCompleted" 
                          [timeFormat]="instanceParams.preferredDateFormat">
                        </app-friendly-time>
                      </td>
                      <td>{{ticket.LocalRequestId}}</td>
                      <td>{{ticket.RequestType}}</td>
                      <td>{{ticket.RequesterName}}</td>
                      <td>{{ticket.Subject}}</td>
                      <td>{{ticket.Priority}}</td>
                      <td>{{ticket.Agent}}</td>
                      <td [ngClass]="getSurveyBackgroundClass(ticket.SurveyRating)">
                        <span class="label m-0">{{ticket.SurveyResult}}</span>
                      </td>
                      <td>
                        {{ticket.SurveyComment}}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>

              <div *ngIf="!surveyFilterTicketsRating">
                <ng-container>
                  <span class="pagination-mytickets">
                      <p-paginator (onPageChange)="onPageChange($event)" [first]="first_survey" [rows]="rows_survey" [totalRecords]='surveyTotalResponses()' [rowsPerPageOptions]="[50,100,500]" ></p-paginator>
                  </span>
                </ng-container>
              </div>

              <div *ngIf="surveyFilterTicketsRating==1">
                <ng-container>
                  <span class="pagination-mytickets">
                      <p-paginator (onPageChange)="onPageChange_ratings($event)" [first]="first_good" [rows]="rows_good" [totalRecords]='surveyGoodResponses()' [rowsPerPageOptions]="[50,100,500]" ></p-paginator>
                  </span>
                </ng-container>
              </div>
              <div *ngIf="surveyFilterTicketsRating==2">
                <ng-container>
                  <span class="pagination-mytickets">
                      <p-paginator (onPageChange)="onPageChange_ratings($event)" [first]="first_average" [rows]="rows_average" [totalRecords]='surveyAverageResponses()' [rowsPerPageOptions]="[50,100,500]" ></p-paginator>
                  </span>
                </ng-container>
              </div>
              <div *ngIf="surveyFilterTicketsRating==3">
                <ng-container>
                  <span class="pagination-mytickets">
                      <p-paginator (onPageChange)="onPageChange_ratings($event)" [first]="first_poor" [rows]="rows_poor" [totalRecords]='surveyBadResponses()' [rowsPerPageOptions]="[50,100,500]" ></p-paginator>
                  </span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
