                                                                                                                                                                                        
import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {AdminCustomSurveyService} from "../../../../../services/api/admin/admin-custom-survey/admin-custom-survey.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminCustomSurveyCreateEditDialogComponent} from "./admin-custom-survey-create-edit-dialog/admin-custom-survey-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import { ToastrService } from "ngx-toastr";
                                                                                                                   
@Component({
    selector: 'app-admin-custom-survey',
    templateUrl: './admin-custom-survey.component.html' 
  })
export class AdminCustomSurveyComponent implements OnInit {

  @Input() requestTypeId;

  data;
  isLoading = true;
  isSaving = false;
  isSavingFreq = false;
  triggerDelay:number=null;
  surveyreminder:number;
  surveyRatio: number;
  isSavingRatio: boolean;
  isSavingReminder: boolean;
  ratioRange=[1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  constructor(private apiAdminCustomSurveyService: AdminCustomSurveyService,
              private dialogService: DialogService,
              private viewReference: ViewContainerRef,
              private toastr: ToastrService) {  }

  ngOnInit(): void {
    this.getData();
    console.log(this.requestTypeId);
    this.getTriggerDelay();
    this.getSurveyReminder();
    this.getSurveyRatio();
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCustomSurveyService.listAll(this.requestTypeId).then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }  

  getTriggerDelay() {
    if (!this.requestTypeId) {
      console.error("RequestTypeId is required to fetch trigger delay.");
      return;
    }

    this.apiAdminCustomSurveyService.getTriggerDelay(this.requestTypeId).then(
      (response: any) => {
        this.triggerDelay = response.HrsDelay;
        console.log(this.triggerDelay);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  updateTriggerDelay() {
    if (!this.requestTypeId) {
      return;
    }
    // validate if the Trigger delay less than 0
    if (this.triggerDelay < 0) {
      this.toastr.error("Invalid Trigger Delay format. Please provide a valid format.");
      return;
  }

  if(this.triggerDelay === null){
    this.toastr.warning("Error:Trigger Delay is required");
    return
  }
    this.isSaving = true;
    this.apiAdminCustomSurveyService.updateTriggerDelay(this.requestTypeId, { TriggerDelay: this.triggerDelay }).then(
      () => {
        this.isSaving = false;
        this.toastr.success("Trigger delay updated successfully!");
      },
      (error) => {
        this.isSaving = false;
        console.error(error);
      }
    );
  }
  
  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminCustomSurveyCreateEditDialogComponent, {id: null, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminCustomSurveyCreateEditDialogComponent, {id: id, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {                                                                                                         

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminCustomSurveyService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }

  getSurveyReminder() {
    this.apiAdminCustomSurveyService.getSurveyReminder(this.requestTypeId).then(response => {
      this.surveyreminder = response[0]["SurveyReminder"];
      // console.log(response[0]["SurveyReminder"]);
      this.isLoading = false;
    })
  }

  updateSurveyReminder() {
// validate if the surveyReminder less than 0 
    if (this.surveyreminder<0) {
        this.toastr.error("Invalid survey reminder format. Please provide a valid format.");
        return;
    }

    if (this.surveyreminder=== null) {
      this.toastr.warning("Error: Survey reminder is required!");
      return;
    }

    this.isSavingReminder = true;
    // Proceed with the update if the value is valid
    this.apiAdminCustomSurveyService.updateSurveyReminder(this.requestTypeId, { SurveyReminder: this.surveyreminder })
        .then(() => {
            this.toastr.success("Survey reminder updated successfully!");
            this.isSavingReminder = false;
        })
        .catch((error) => {
            this.toastr.error("Failed to update survey reminder. Please try again.");
            console.error("Update Error:", error);
            this.isSavingReminder = false;
        });
}
  getSurveyRatio() {
    this.apiAdminCustomSurveyService.getSurveyRatio(this.requestTypeId).then((response: any) => {
      this.surveyRatio = response.RandomSurveyRatio || 1; // Default to 1 if not set
    }, error => {
      console.error(error);
    });
  }
  
  updateSurveyRatio() {
    // if (!this.surveyRatio || this.surveyRatio < 1 || this.surveyRatio > 5) {
    //   this.toastr.error("Invalid survey ratio. Please select a value between 1 and 5.");
    //   return;
    // }
    this.isSavingRatio = true;
    this.apiAdminCustomSurveyService.updateSurveyRatio(this.requestTypeId, { Ratio: this.surveyRatio }).then(() => {
      this.toastr.success("Survey ratio updated successfully!");
      this.isSavingRatio = false;
    }).catch(error => {
      console.error("Failed to update survey ratio:", error);
      this.isSavingRatio = false;
    });
  }
}
                                                                                   