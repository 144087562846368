import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-highchart-pie',
  templateUrl: './highchart-pie.component.html',
  styleUrls: ['./highchart-pie.component.css']
})
export class HighchartPieComponent implements OnInit {

  @Input() inputData: [any];
  @Input() useStandardColours: Boolean = false;

  public showGraph = false;

  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  chartOptions;
  chartCallback: Highcharts.ChartCallbackFunction = function (chart) { null } // optional function, defaults to null
  updateFlag: boolean = true; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = false; // optional boolean, defaults to false

  ngOnInit(): void {

    // Colours:
    this.inputData.forEach(row => {
      if(!row.hasOwnProperty('Colour')){
        let row_name = row.name.toString(); // Convert row.name to string
        row.Colour = this.assignColour(row_name);
      }
    })

    let arrayOfColours = this.inputData.map(a => a.Colour);

    if(this.useStandardColours){
      arrayOfColours = [
        "#98c1d9",
        "#db946d",
        "#70ab9c",
        "#e59b98",
        "#a7a02d",];
    }

    this.chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        backgroundColor: 'rgba(0,0,0,0)',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b> ({point.percentage:.1f}%)'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          borderWidth: 2,
          borderColor: 'white',
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.percentage:.1f} %',
            style: {
              fontFamily: 'Noto Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
              fontSize: '13px',
              border: 'none',
              textOutline: 'none',
              color: '#858c90',
              fontWeight: 'none'
            }
          }
        }
      },
      series: [{
        name: 'Tickets',
        type: 'pie',
        colorByPoint: true,
        data: this.inputData,
        colors: arrayOfColours
      }]
    };

    this.showGraph = true;
  }

  assignColour(value){

    switch (value.toLowerCase()) {
      case 'urgent':
        return '#e76f51';
      case 'high':
        return '#f3722c';
      case 'medium':
        return '#f9c74f';
      case 'low':
        return '#43aa8b';
      case 'p1':
        return '#e76f51';
      case 'p2':
        return '#f3722c';
      case 'p3':
        return '#f9c74f';
      case 'p4':
        return '#43aa8b';
      case 'unassigned':
        return '#c6c6c6';
    }

    // if value contains p1
    if(value.toLowerCase().includes('1')){
      return '#e76f51';
    }

    // if value contains p2
    if(value.toLowerCase().includes('2')){
      return '#f3722c';
    }

    // if value contains p3
    if(value.toLowerCase().includes('3')){
      return '#f9c74f';
    }

    // if value contains p4
    if(value.toLowerCase().includes('4')){
      return '#43aa8b';
    }

    // if value contains low
    if(value.toLowerCase().includes('low')){
      return '#43aa8b';
    }

    // if value contains medium
    if(value.toLowerCase().includes('medium')){
      return '#f9c74f';
    }

    // if value contains high
    if(value.toLowerCase().includes('high')){
      return '#f3722c';
    }

    // if value contains urgent
    if(value.toLowerCase().includes('urgent')){
      return '#e76f51';
    }

    // if value contains emergency
    if(value.toLowerCase().includes('emergency')){
      return '#e76f51';
    }

    // if value contains minor
    if(value.toLowerCase().includes('minor')){
      return '#43aa8b';
    }

    // if value contains major
    if(value.toLowerCase().includes('major')){
      return '#f3722c';
    }

    // if value contains critical
    if(value.toLowerCase().includes('critical')){
      return '#e76f51';
    }

    // return default
    return '#c6c6c6';

  }


}
