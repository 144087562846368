<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
  <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>New {{ticketOptions.RequestTypeName}}</h3>
  </div>
  <hr>
  <div class="container p-0">
    <div class="row sdesk-grey-row">
          <div class="col-2 formHeader p-10">
            Requester <span class="text-danger">*</span>

          </div>
          <div class="col p-10">
            <i class="fas fa-user icon-size-medium m-r-10"></i>
            {{this.getValueName(this.ticketData.RequesterId, this.ticketOptions.requester)}}
          </div>
          <div class="col-1">
            <div (click)="openUserSearchModal()" class="sdesk-right-button divLink vertical-center">
              <i style="margin-top: 11px;" class="fas fa-search icon-size-medium"></i>
            </div>
          </div>
      <div class="col-1">
        <div (click)="openCreateUserModal()" class="sdesk-right-button divLink vertical-center">
          <i style="margin-top: 11px;" class="fas fa-user-plus icon-size-medium divLink"></i>
        </div>
      </div>
    </div>

    <div class="row sdesk-grey-row">
      <div class="row" *ngIf="ticketOptions.RequestTypeOptions.CustomerEnabled">
        <div class="col-2 formHeader p-10">
          Customer
        </div>
        <div class="col p-10">
          <i class="fas fa-building icon-size-medium m-r-10"></i>
          {{this.getValueName(this.ticketData.CustomerId, this.ticketOptions.customer)}}
        </div>
        <div class="col-1">
          <div  (click)="openCustomerServiceSearchModal()" style="
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
" class="sdesk-right-button divLink vertical-center">
          </div>
        </div>
      </div>

      <div class="row" *ngIf="ticketOptions.RequestTypeOptions.CustomerEnabled">
        <div class="col-2 formHeader p-10">
          Location
        </div>
        <div class="col p-10">
          <i class="fas fa-map-marker-alt icon-size-medium m-r-10"></i>
          {{this.getValueName(this.ticketData.CustomerLocationId, this.ticketOptions.customerLocations)}}

        </div>
        <div class="col-1">
          <div style="
          border-radius: 0px;" (click)="openCustomerServiceSearchModal()" class="sdesk-right-button divLink vertical-center">
            <i class="fas fa-search icon-size-medium"></i>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="ticketOptions.RequestTypeOptions.ServiceEnabled">
        <div class="col-2 formHeader p-10">
          Service
        </div>
        <div class="col p-10">
          <i class="fas fa-cog icon-size-medium m-r-10"></i>
          {{this.getValueName(this.ticketData.ServiceId, this.ticketOptions.service)}}
        </div>
        <div class="col-1">
          <div  (click)="openCustomerServiceSearchModal()" style="
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
" class="sdesk-right-button divLink vertical-center">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-2 formHeader alignMiddle p-10">
        Subject <span class="text-danger">*</span>
      </div>
      <div class="col p-10">
        <input [(ngModel)]="ticketData.RequestSubject" type="text" class="form-control" id="Subject" placeholder="Enter Subject" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-2 formHeader p-10">
        Description <span class="text-danger">*</span>
      </div>
      <div class="col p-10">
        <quill-editor id="RequestBody" [(ngModel)]="ticketData.RequestBody" [styles]="{height: '150px'}"
        placeholder=""></quill-editor>
      </div>
    </div>


    <div class="row sdesk-grey-row" *ngIf="ticketOptions.RequestTypeOptions.ImpactEnabled">
        <div class="col-2 formHeader alignMiddle p-10">
          Impact
        </div>
        <div class="col p-10">

          <ng-select [items]="ticketOptions.impact"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="ticketData.ImpactId"
                     [clearable]="false">
          </ng-select>

<!--          <select [(ngModel)]="ticketData.ImpactId" class="form-control input-sm" id="Impact" data-style="btn-white">-->
<!--            <option [value]="null" disabled></option>-->
<!--            <option *ngFor="let option of ticketOptions.impact" [value]="option.id" [label]="option.name"></option>-->
<!--          </select>-->
        </div>
    </div>

    <div class="row sdesk-grey-row" *ngIf="ticketOptions.RequestTypeOptions.UrgencyEnabled">
      <div class="col-2 formHeader alignMiddle p-10">
        Urgency
      </div>
      <div class="col p-10">

        <ng-select [items]="ticketOptions.urgency"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="ticketData.UrgencyId"
                   [clearable]="false">
        </ng-select>

<!--        <select [(ngModel)]="ticketData.UrgencyId" class="form-control input-sm" id="Urgency" data-style="btn-white">-->
<!--          <option [value]="null" disabled></option>-->
<!--          <option *ngFor="let option of ticketOptions.urgency" [value]="option.id" [label]="option.name"></option>-->
<!--        </select>-->
      </div>
    </div>

    <div class="row sdesk-grey-row" *ngIf="ticketOptions.RequestTypeOptions.PriorityEnabled && !ticketOptions.RequestTypeOptions.AutoPriority">
      <div class="col-2 formHeader alignMiddle p-10">
        Priority
      </div>
      <div class="col p-10">

        <ng-select [items]="ticketOptions.priority"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="ticketData.PriorityId"
                   [clearable]="false">
        </ng-select>

<!--        <select [(ngModel)]="ticketData.PriorityId" class="form-control input-sm" id="Priority" data-style="btn-white">-->
<!--          <option [value]="null" disabled></option>-->
<!--          <option *ngFor="let option of ticketOptions.priority" [value]="option.id" [label]="option.name"></option>-->
<!--        </select>-->
      </div>
    </div>

    <div class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10">
        Group  <span class="text-danger">*</span>
      </div>
      <div class="col p-10">

        <ng-select [items]="ticketOptions.group"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="ticketData.GroupId"
                   [clearable]="false"
        (ngModelChange)="refreshOptions(true)">
        </ng-select>

<!--        <select [(ngModel)]="ticketData.GroupId" (ngModelChange)="refreshOptions(true)" class="form-control input-sm" id="Group" data-style="btn-white">-->
<!--          <option [value]="null" disabled></option>-->
<!--          <option *ngFor="let option of ticketOptions.group" [value]="option.id" [label]="option.name"></option>-->
<!--        </select>-->
      </div>
    </div>

    <div class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10">
        Agent
      </div>
      <div class="col p-10">

        <ng-select [items]="ticketOptions.agent"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="ticketData.AgentId"
                   [clearable]="true"
                   [disabled]="!ticketData.GroupId">
        </ng-select>

<!--        <select [(ngModel)]="ticketData.AgentId" class="form-control input-sm" id="Agent" data-style="btn-white">-->
<!--          <option [value]="null" disabled></option>-->
<!--          <option *ngFor="let option of ticketOptions.agent" [value]="option.id" [label]="option.name"></option>-->
<!--        </select>-->
      </div>
    </div>

    <div *ngFor="let customField of ticketOptions.CustomFields" class="row sdesk-grey-row">
      <div class="col-2 formHeader alignMiddle p-10  text-break">
        {{customField.Name}}<span *ngIf="customField.Required" class="text-danger">*</span>
      </div>
      <div class="col p-10">
        <ng-container *ngIf="customField.FieldType === 2">
          <app-custom-field-dropdown
            (optionOutput)="setCustomFieldValue(customField, $event)"
            [customFieldId]="customField.CustomFieldId"
            [customFieldOptions]="customField.Options"
            [selectedLevelValues]="{ Level1SelectedId: null, Level2SelectedId: null,Level3SelectedId: null }">

          </app-custom-field-dropdown>
        </ng-container>
        <ng-container *ngIf="customField.FieldType === 1">
          <!-- Show textbox for FieldType 1 -->
          <input [(ngModel)]="customField.Value" type="text" class="form-control" [id]="'customField_' + customField.CustomFieldId" [placeholder]="'Enter ' + customField.Name" autocomplete="off" (input)="setCustomFieldValue(customField, $event.target.value)">
        </ng-container>
        <ng-container *ngIf="customField.FieldType === 3">
          <!-- Show textbox for FieldType 1 -->
          <!-- <input type="date" [id]="'customField_' + customField.CustomFieldId" [(ngModel)]="customField.Value"
            class="form-control" autocomplete="off" [placeholder]="'Enter ' + customField.Name" autocomplete="off"
            (input)="setCustomFieldValue(customField, $event.target.value)"> -->
          <p-calendar [(ngModel)]="customField.Value" [showIcon]="true" ariaLabel="Date" [iconDisplay]="'input'"
            inputId="'customField_' + customField.CustomFieldId" [readonlyInput]="true" [showButtonBar]="true"
            [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"
            (onSelect)="setCustomFieldValue(customField, $event)"
            ></p-calendar>
        </ng-container>
      </div>
    </div>


    <div class="m-t-20">
      <div (click)="save()" [class.disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading">Creating...</span>
        <span *ngIf="!loading">Create</span>
      </div>
    </div>

  </div>
</app-blade-right>
