                                                                                                                                                                                        
import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {AdminCustomSurveyService} from "../../../../../services/api/admin/admin-custom-survey/admin-custom-survey.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminCustomSurveyCreateEditDialogComponent} from "./admin-custom-survey-create-edit-dialog/admin-custom-survey-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import { ToastrService } from "ngx-toastr";
                                                                                                                   
@Component({
    selector: 'app-admin-custom-survey',
    templateUrl: './admin-custom-survey.component.html' 
  })
export class AdminCustomSurveyComponent implements OnInit {

  @Input() requestTypeId;

  data;
  isLoading = true;
  isSaving = false;
  triggerDelay:number=null;
  surveyfrequency:number;

  constructor(private apiAdminCustomSurveyService: AdminCustomSurveyService,
              private dialogService: DialogService,
              private viewReference: ViewContainerRef,
              private toastr: ToastrService) {  }

  ngOnInit(): void {
    this.getData();
    console.log(this.requestTypeId);
    this.getTriggerDelay();
    this.getSurveyFrequency();
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCustomSurveyService.listAll(this.requestTypeId).then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }  

  getTriggerDelay() {
    if (!this.requestTypeId) {
      console.error("RequestTypeId is required to fetch trigger delay.");
      return;
    }

    this.apiAdminCustomSurveyService.getTriggerDelay(this.requestTypeId).then(
      (response: any) => {
        this.triggerDelay = response.HrsDelay;
        console.log(this.triggerDelay);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  updateTriggerDelay() {
    if (!this.requestTypeId) {
      return;
    }
    // validate if the Trigger delay less than 0
    if (this.triggerDelay < 0) {
      this.toastr.error("Invalid Trigger Delay format. Please provide a valid format.");
      return;
  }

  if(this.triggerDelay === null){
    this.toastr.warning("Error:Trigger Delay is required");
    return
  }
    this.isSaving = true;
    this.apiAdminCustomSurveyService.updateTriggerDelay(this.requestTypeId, { TriggerDelay: this.triggerDelay }).then(
      () => {
        this.isSaving = false;
        this.toastr.success("Trigger delay updated successfully!");
      },
      (error) => {
        this.isSaving = false;
        console.error(error);
      }
    );
  }
  
  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminCustomSurveyCreateEditDialogComponent, {id: null, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminCustomSurveyCreateEditDialogComponent, {id: id, requestTypeId: this.requestTypeId}, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {                                                                                                         

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminCustomSurveyService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }

  getSurveyFrequency() {
    this.apiAdminCustomSurveyService.getSurveyFrequency(this.requestTypeId).then(response => {
      this.surveyfrequency = response[0]["SurveyFrequency"];
      // console.log(response[0]["SurveyFrequency"]);
      this.isLoading = false;
    })
  }

  updateSurveyFrequency() {
// validate if the surveyfrequency less than 0
    if (this.surveyfrequency<0) {
        this.toastr.error("Invalid survey frequency format. Please provide a valid format.");
        return;
    }

    if (this.surveyfrequency=== null) {
      this.toastr.warning("Error: Survey frequency is required!");
      return;
    }

    // Proceed with the update if the value is valid
    this.apiAdminCustomSurveyService.updateSurveyFrequency(this.requestTypeId, { SurveyFrequency: this.surveyfrequency })
        .then(() => {
            this.toastr.success("Survey frequency updated successfully!");
        })
        .catch((error) => {
            this.toastr.error("Failed to update survey frequency. Please try again.");
            console.error("Update Error:", error);
        });
}
  
}
                                                                                   